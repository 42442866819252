import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const PoliticalPositionRole = ({ data, setKycData, kycFields, translator }) => {
  const { political_position_role: positions } = kycFields;
  const fieldKey = KycKeys.POLITICAL_POSITION_ROLE;
  const politicalExistingFieldKey = KycKeys.POLITICAL_POSITION_EXISTING;

  const {
    [fieldKey]: { value, error },
    [politicalExistingFieldKey]: { value: politicalExistingValue },
  } = data;

  const isVisible = politicalExistingValue;

  const items = Object.entries(positions)?.map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value: value,
        error: fieldErrorObject.errorMessage,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"politicalPosition"}
      inputOptions={items}
      label={translator("politicalPosition")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

PoliticalPositionRole.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default PoliticalPositionRole;
