import { generalEnums } from "../enums/general";

const convertBooleanToYesNo = (value) => {
  if (value) {
    return generalEnums.YES;
  } else if (value === false) {
    return generalEnums.NO;
  } else {
    return "";
  }
};

const convertYesNoToBoolean = (value) => {
  const mapping = {
    [generalEnums.YES]: true,
    [generalEnums.NO]: false,
  };

  return mapping[value] ?? null;
};

export { convertBooleanToYesNo, convertYesNoToBoolean };
