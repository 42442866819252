import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { countriesEnums } from "../../../enums/general";

const PassportCountry = ({ data, setKycData, countries = [], translator }) => {
  const fieldKey = KycKeys.PASSPORT_COUNTRY;
  const nationalityKey = KycKeys.NATIONALITY;

  const {
    [fieldKey]: { value, error },
    [nationalityKey]: { value: nationalityValue },
  } = data;

  const isVisible =
    nationalityValue && nationalityValue !== countriesEnums.KUWAIT;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const countriesOptions = countries?.map((country) => ({
    value: country["alpha-2"],
    label: country.name,
  }));

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"passportCountry"}
      label={translator("passportCountry")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      inputOptions={countriesOptions}
    />
  );
};

PassportCountry.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  translator: PropTypes.func.isRequired,
};

export default PassportCountry;
