import React, { useState } from "react";
import styles from "./alert-photo-capture.module.scss";
import PropTypes from "prop-types";

const AlertPhotoCapture = ({ text, rightIcon, styling, setShowAlert }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
    setShowAlert(false);
  };

  const { backGroundColor, textColor } = styling;

  if (isClosed) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: backGroundColor }}
    >
      <p className={styles.alertMessage} style={{ color: textColor }}>
        {text}
      </p>
      {rightIcon && (
        <img
          className={styles.closeIcon}
          src={rightIcon}
          alt="Close Icon"
          onClick={handleClose}
        />
      )}
      <img
        className={styles.mobileCloseIcon}
        src={rightIcon}
        alt="Mobile Close Icon"
        onClick={handleClose}
      />
    </div>
  );
};

AlertPhotoCapture.propTypes = {
  text: PropTypes.string,
  rightIcon: PropTypes.string,
  setShowAlert: PropTypes.func,
  styling: PropTypes.object,
};

export default AlertPhotoCapture;
