import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import employerTypeEnums from "../../../enums/employer-type";
import employmentStatusEnums from "../../../enums/employment-status-enum";
import PropTypes from "prop-types";

const PrivateBusinessName = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.EMPLOYMENT_COMPANY;
  const employerTypeKey = KycKeys.EMPLOYER_TYPE;
  const employmentStatuskey = KycKeys.EMPLOYMENT_STATUS;
  const lastEmploymentStatusKey = KycKeys.LAST_EMPLOYMENT_STATUS;
  const {
    [fieldKey]: { value, error },
    [employerTypeKey]: { value: employerTypeValue },
    [employmentStatuskey]: { value: employmentStatusValue },
    [lastEmploymentStatusKey]: { value: lastEmploymentStatusValue },
  } = data;

  const isSelfEmployed = [
    employmentStatusValue,
    lastEmploymentStatusValue,
  ].some((value) => value === employmentStatusEnums.SELF_EMPLOYED);

  const isVisible = employerTypeValue === employerTypeEnums.PRIVATE_SECTOR;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateTextField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return;
  }

  return (
    <InputField
      name={"employerName"}
      isDisabled={false}
      label={
        isSelfEmployed
          ? translator("privateBusinessNameLabel")
          : translator("employerNameLabel")
      }
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

PrivateBusinessName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default PrivateBusinessName;
