import React from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";

const AccountPurpose = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.ACCOUNT_PURPOSE;
  const accountPurposeOtherKey = KycKeys.ACCOUNT_PURPOSE_OTHER;

  const { accountPurposes } = kycFields;
  const {
    [fieldKey]: { value, error },
  } = data;

  const accountPurposesOptions = Object.keys(accountPurposes)?.map((key) => ({
    label: accountPurposes[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        error: errorMessageReceived,
      },

      [accountPurposeOtherKey]: {
        ...prev[accountPurposeOtherKey],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };

  return (
    <CustomSelect
      name={"accountPurpose"}
      label={translator("purposeOfOpeningTheAccountLabel")}
      onChange={handleChange}
      value={value}
      inputOptions={accountPurposesOptions}
      errorMessage={error}
    />
  );
};

AccountPurpose.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default AccountPurpose;
