import React, { useEffect, useState } from "react";
import PrimaryButton from "../../widgets/buttons/primary-button";
import MainLayout from "../../layouts/main-layout";
import styles from "./sucess-page.module.scss";
import PropTypes from "prop-types";
import { getCustomer } from "../../general-services.proxy";
import { useNavigate } from "react-router-dom";
import {
  emailAddressDetailsRoute,
  journeyTimeLineRoute,
} from "../../routes/routes.const";
import { useTranslation } from "react-i18next";

const SuccessPage = ({ icon, title, subTitle }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [isEmailUpdated, setIsEmailUpdated] = useState(null);

  const fetchCustomerData = async () => {
    try {
      const { isEmailUpdated } = await getCustomer();
      setIsEmailUpdated(isEmailUpdated);
    } catch (exception) {
      console.error(exception);
    }
  };

  const renderFooter = () => {
    const onContinue = () => {
      if (isEmailUpdated) {
        navigate(journeyTimeLineRoute, {
          replace: true,
        });
      } else {
        navigate(emailAddressDetailsRoute, {
          replace: true,
        });
      }
    };
    return (
      <PrimaryButton onClick={onContinue} data-testid={"continue"}>
        {t("continue")}
      </PrimaryButton>
    );
  };

  useEffect(() => {
    fetchCustomerData();
  }, []);

  return (
    <MainLayout footer={renderFooter}>
      <div className={styles.container}>
        <img className={styles.imageStyle} src={icon} alt="Success" />
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitleMessage}>{subTitle}</span>
      </div>
    </MainLayout>
  );
};

SuccessPage.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default SuccessPage;
