import React, { useEffect, useState } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";
import { useTranslation } from "react-i18next";
import { languageEnum } from "../../../enums/language-enum";

const City = ({ data, setData, cities, translator }) => {
  const { i18n } = useTranslation();
  const fieldKey = KycKeys.ADDRESS_CITY;
  const areaFieldKey = KycKeys.ADDRESS_AREA;
  const {
    [fieldKey]: { value, error, isDisabled },
  } = data;

  const [cityLanguage, setCityLanguage] = useState(languageEnum.english);
  const citiesOptions = cities.map((city) => ({
    value: city.name[languageEnum.english],

    label: city.name[cityLanguage],
  }));

  const validate = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);

    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
      [areaFieldKey]: { ...prev[areaFieldKey], value: "" },
    }));
  };

  const handleChange = (value) => {
    validate(value);
  };

  useEffect(() => {
    setCityLanguage(i18n.language);
  }, [i18n, i18n.language]);

  return (
    <CustomSelect
      name={"city"}
      isDisabled={isDisabled}
      label={translator("city")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      inputOptions={citiesOptions}
    />
  );
};

City.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  cities: PropTypes.array.isRequired,
  translator: PropTypes.func.isRequired,
};

export default City;
