import React from "react";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const CivilIdNumber = ({ data, translator }) => {
  const fieldKey = KycKeys.CIVIL_ID_NUMBER;

  const {
    [fieldKey]: { value, isDisabled, error },
  } = data;

  return (
    <InputField
      label={translator("civilIdNumber")}
      value={value}
      isDisabled={isDisabled}
      errorMessage={error}
    />
  );
};

CivilIdNumber.propTypes = {
  data: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default CivilIdNumber;
