import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import InputField from "../../../widgets/inputs/primary-input";

const TaxPayerIdentification = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.TAX_PAYER_IDENTIFICATION;
  const usCitizenKey = KycKeys.US_CITIZEN;
  const {
    [fieldKey]: { value, error, isDisabled },
    [usCitizenKey]: { value: usCitizenValue },
  } = data;

  const isVisible = usCitizenValue;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateTaxPayerId(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = ({ target: { value } }) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name={"taxPayerId"}
      isDisabled={isDisabled}
      label={translator("taxPayerId")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

TaxPayerIdentification.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default TaxPayerIdentification;
