import React, { useState, useEffect } from "react";
import InputField from "../../../widgets/inputs/primary-input";
import infoIcon from "../../../assets/icons/info-icon.svg";
import FieldsValidation from "../../../validation/fields-validation";
import KycKeys from "../../../enums/kyc-enum";
import { setHideNavigation } from "../../../store/slices/general.slice";
import { useSelector, useDispatch } from "react-redux";
import civilIdSerialInfo from "../../../assets/images/civil-id-serial-number.svg";
import civilIdSerialInfoArabic from "../../../assets/images/civil-id-serial-number-arabic.svg";
import OverlayModal from "../../../modals/overlay/overlay-modal";
import { useTranslation } from "react-i18next";

const SerialNumberField = ({ data, setData, setException }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("common");
  const { serialNumber } = useSelector((state) => state.general.userInfo);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [language, setLanguage] = useState("ar");
  const fieldKey = KycKeys.CIVIL_ID_SERIAL;
  const {
    [fieldKey]: { value, error },
  } = data;

  useEffect(() => {
    const language = i18n.language;
    setLanguage(language);
  }, [i18n, i18n.language]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value: serialNumber,
      },
    }));
  }, []);

  const handleIconClick = () => {
    dispatch(setHideNavigation(true));
    setOverlayVisible(true);
  };

  const handleCloseClick = () => {
    dispatch(setHideNavigation(false));
    setOverlayVisible(false);
  };

  const validateField = (value) => {
    setException("");
    const fieldErrorObject = FieldsValidation.validateSerialIdNumber(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  const getImageVersion = () =>
    language === "en" ? civilIdSerialInfo : civilIdSerialInfoArabic;

  return (
    <>
      <InputField
        icon={infoIcon}
        inputadornment="end"
        onIconClick={handleIconClick}
        isDisabled={false}
        label={t("serialNumberLabel")}
        onChange={handleChange}
        value={value}
        errorMessage={error}
        name={"civilSerial"}
      />
      {isOverlayVisible && (
        <OverlayModal
          title={t("serialIdGuide")}
          fieldInfoImage={getImageVersion()}
          handleCloseClick={handleCloseClick}
        />
      )}
    </>
  );
};
export default SerialNumberField;
