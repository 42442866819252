import {
  commonValidations,
  civilIDValidations,
  civilIDSerialNumberValidations,
  emailAndMobileValidations,
  OTPNumberValidations,
} from "../utils/validation/validation-messages";
import ValidationUtil from "../utils/validation/validation-utils";

class FieldsValidation {
  static validateCivilIdNumber(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsArabicNumbers(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishDigits,
      };
    } else if (ValidationUtil.containsAlphabeticalChars(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noAlphabeticalCharacters,
      };
    } else if (value && !ValidationUtil.isExactLength(value, 12)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: civilIDValidations.civilIdlength,
      };
    }

    return validationResult;
  }

  static validateSerialIdNumber(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsArabicNumbers(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishDigits,
      };
    } else if (ValidationUtil.containsAlphabeticalChars(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.noAlphabeticalCharacters,
      };
    } else if (value && !ValidationUtil.isExactLength(value, 10)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: civilIDSerialNumberValidations.civilSeriallength,
      };
    }

    return validationResult;
  }

  static validateMobileNumber(value, code) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };

    const kuwaitiCountryCodeNumber = "+965";

    if (ValidationUtil.isEmpty(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsArabicNumbers(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishDigits,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsAlphabeticalChars(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.noAlphabeticalCharacters,
      };
    } else if (
      code === kuwaitiCountryCodeNumber &&
      value &&
      !ValidationUtil.isKuwaitiNumber(value)
    ) {
      validationResult = {
        isErrorExists: true,
        errorMessage: emailAndMobileValidations.kuwaitNumberRules,
      };
    }

    return validationResult;
  }

  static validateEmail(value, confirmedEmail, isConfirmedEmailError) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.isEmpty(value) || !value) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (value && !ValidationUtil.validateEmail(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: emailAndMobileValidations.enterValidEmail,
      };
    }

    return validationResult;
  }

  static validateConfirmEmailAddress(
    value,
    submittedEmailAddress,
    isEmailError
  ) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.isEmpty(value) || !value) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (value && !ValidationUtil.validateEmail(value)) {
      validationResult = {
        isErrorExists: true,
        errorMessage: emailAndMobileValidations.enterValidEmail,
      };
    } else if (
      submittedEmailAddress &&
      value !== submittedEmailAddress &&
      !isEmailError
    ) {
      validationResult = {
        isErrorExists: true,
        errorMessage: emailAndMobileValidations.enterValidConfirmedEmail,
      };
    }

    return validationResult;
  }

  static validateOTP(value) {
    let validationResult = {
      isErrorExists: false,
      errorMessage: "",
    };
    if (ValidationUtil.isEmpty(value) || !value) {
      validationResult = {
        isErrorExists: true,
        errorMessage: commonValidations.required,
      };
    } else if (ValidationUtil.containsWhiteSpace(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpaces,
      };
    } else if (ValidationUtil.containsSpecialCharacters(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.noSpecialCharacters,
      };
    } else if (ValidationUtil.containsArabicNumbers(value)) {
      validationResult = {
        ...validationResult,
        isErrorExists: true,
        errorMessage: commonValidations.onlyEnglishDigits,
      };
    } else if (
      value &&
      !ValidationUtil.isExactLengthForCharactersDigit(value, 6)
    ) {
      validationResult = {
        isErrorExists: true,
        errorMessage: OTPNumberValidations.Otplength,
      };
    }
    return validationResult;
  }
}

export default FieldsValidation;
