import React from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const EmploymentStatus = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.EMPLOYMENT_STATUS;
  const employerTypeKey = KycKeys.EMPLOYER_TYPE;
  const lastEmploymentStatusKey = KycKeys.LAST_EMPLOYMENT_STATUS;
    const entityTypeKey = KycKeys.GOVERNMENT_ENTITY_TYPE
    const businessTypeKey = KycKeys.EMPLOYMENT_SECTOR
    const businessNameKey = KycKeys.EMPLOYMENT_COMPANY
    const entityNameKey = KycKeys.EMPLOYMENT_ENTITY_NAME
    const organizationNameKey = KycKeys.EMPLOYMENT_ORGANIZATION_NAME
    const autSignFinancialTransactionsKey = KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS
    const businessSectorKey = KycKeys.PRIVATE_SECTOR_INDUSTRY
    const departmentKey = KycKeys.EMPLOYMENT_DEPARTMENT
    const jobTitleKey = KycKeys.JOB_TITLE
  const { employmentStatuses } = kycFields;
  const {
    [fieldKey]: { value, error },
  } = data;

  const employmentStatusOptions = Object.keys(employmentStatuses)?.map(
    (key) => ({
      label: employmentStatuses[key],
      value: key,
    })
  );

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
      [employerTypeKey]: {
        ...prev[employerTypeKey],
        error: "",
        value: "",
      },
      [lastEmploymentStatusKey]: {
        ...prev[lastEmploymentStatusKey],
        error: "",
        value: "",
      },
      [entityTypeKey]: {
        ...prev[entityTypeKey],
        error: "",
        value: "",
      },
      [businessTypeKey]: {
        ...prev[businessTypeKey],
        error: "",
        value: "",
      },
      [businessNameKey]: {
        ...prev[businessNameKey],
        error: "",
        value: "",
      },
      [entityNameKey]: {
        ...prev[entityNameKey],
        error: "",
        value: "",
      },
      [organizationNameKey]: {
        ...prev[organizationNameKey],
        error: "",
        value: "",
      },
      [autSignFinancialTransactionsKey]: {
        ...prev[autSignFinancialTransactionsKey],
        error: "",
        value: "",
      },
      [businessSectorKey]: {
        ...prev[businessSectorKey],
        error: "",
        value: "",
      },
      [departmentKey]: { ...prev[departmentKey], error: "", value: "" },
      [jobTitleKey]: { ...prev[jobTitleKey], error: "", value: "" },
    }));
  };

  const handleChange = (changedVal) => {
    if (value !== changedVal) {
      validateField(changedVal);
    }
  };

  return (
    <CustomSelect
      name={"empStatus"}
      inputOptions={employmentStatusOptions}
      label={translator("employmentStatusLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

EmploymentStatus.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default EmploymentStatus;
