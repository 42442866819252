import React from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const Gender = ({ data, setKycData, kycFieldOptions = {}, translator }) => {
  const fieldKey = KycKeys.GENDER;

  const {
    [fieldKey]: { value, error, isDisabled },
  } = data;

  const { genderOptions = {} } = kycFieldOptions;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const genderListOptions = Object.keys(genderOptions)?.map((key) => ({
    label: genderOptions[key],
    value: key,
  }));

  return (
    <CustomSelect
      name={"gender"}
      label={translator("gender")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      inputOptions={genderListOptions}
      isDisabled={isDisabled}
    />
  );
};

Gender.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFieldOptions: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default Gender;
