import React, { useState, useEffect } from "react";
import styles from "./alert.module.scss";
import PropTypes from "prop-types";

const Alert = ({ text, setText, leftIcon, rightIcon, styling }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
    setText("");
  };

  const { backGroundColor, textColor } = styling;

  if (isClosed) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: backGroundColor }}
    >
      {leftIcon && <img src={leftIcon} alt="Left Icon" />}
      <p
        data-testid="otpErrorMessage"
        className={styles.alertMessage}
        style={{ color: textColor }}
      >
        {text}
      </p>
      {rightIcon && (
        <img
          className={styles.closeIcon}
          src={rightIcon}
          alt="Close Icon"
          onClick={handleClose}
        />
      )}
      <img
        className={styles.mobileCloseIcon}
        src={rightIcon}
        alt="Mobile Close Icon"
        onClick={handleClose}
      />
    </div>
  );
};

Alert.propTypes = {
  text: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  setText: PropTypes.func,
  styling: PropTypes.object,
};

export default Alert;
