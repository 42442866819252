import React, { useEffect } from "react";
import InputField from "../../../widgets/inputs/primary-input";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import { incomeSourceEnums } from "../../../enums/income-and-wealth";
import PropTypes from "prop-types";

const ClientIncomeDetails = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.INCOME_DETAILS;
  const incomeSourceKey = KycKeys.INCOME_SOURCE_V2;

  const {
    [fieldKey]: { value, error },
  } = data;

  const {
    [incomeSourceKey]: { value: incomeSourceValue },
  } = data;

  const isOtherDetailsVisible = incomeSourceValue.some(
    (obj) => obj === incomeSourceEnums.OTHER
  );

  const isInvestmentDetailsVisible = incomeSourceValue.some(
    (obj) => obj === incomeSourceEnums.INVESTMENT
  );

  const isRealEstateDetailsVisible = incomeSourceValue.some(
    (obj) => obj === incomeSourceEnums.REAL_ESTATE
  );

  const isVisible =
    isOtherDetailsVisible ||
    isInvestmentDetailsVisible ||
    isRealEstateDetailsVisible;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateIncomeDetailsText(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name={"clientIncomeDetails"}
      isDisabled={false}
      label={translator("incomeDetailsLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};
ClientIncomeDetails.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};
export default ClientIncomeDetails;
