import React from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";

const PayTaxesInAnotherCountry = ({
  data,
  setKycData,
  translator,
  kycFields,
}) => {
  const fieldKey = KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY;
  const usCitizenKey = KycKeys.US_CITIZEN;
  const taxCountry2Key = KycKeys.TAX_COUNTRY_2;
  const taxCountry3Key = KycKeys.TAX_COUNTRY_3;
  const taxPayerId2Key = KycKeys.TAX_PAYER_ID_2;
  const taxPayerId3Key = KycKeys.TAX_PAYER_ID_3;
  const { yes_no: yesOrNo } = kycFields;
  const {
    [fieldKey]: { value, error, isDisabled },
    [usCitizenKey]: { value: usCitizenValue },
  } = data;
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));
  const payTaxesInAnotherCountryValue = convertBooleanToYesNo(value);
  const disabled = usCitizenValue || isDisabled;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: convertYesNoToBoolean(value),
      },
      [taxCountry2Key]: {
        ...prev[taxCountry2Key],
        error: "",
        value: "",
      },
      [taxCountry3Key]: {
        ...prev[taxCountry3Key],
        error: "",
        value: "",
      },
      [taxPayerId2Key]: {
        ...prev[taxPayerId2Key],
        error: "",
        value: "",
      },
      [taxPayerId3Key]: {
        ...prev[taxPayerId3Key],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  return (
    <CustomSelect
      name={"payTaxInAnotherCountry"}
      inputOptions={options}
      label={translator("payTaxInAnotherCountry")}
      onChange={handleChange}
      value={payTaxesInAnotherCountryValue}
      errorMessage={error}
      isDisabled={disabled}
    />
  );
};

PayTaxesInAnotherCountry.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default PayTaxesInAnotherCountry;
