const businessSectorEnums = {
  CONSUMER: "consumer",
  ENERGY_UTILITIES: "energy_utilities",
  FINANCIAL: "financial",
  HEALTH_CARE_SPORTS: "health_care_sports",
  INDUSTRIAL_MANUFACTURING: "industrial_manufacturing",
  PROFESSIONAL_SERVICES: "professional_services",
  REAL_ESTATE: "real_estate",
  TELECOM_MEDIA_TECHNOLOGY: "telecom_media_technology",
  TRAVEL_TRANSPORTATION: "travel_transportation",
};
export default businessSectorEnums;
