import React from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";
import PropTypes from "prop-types";

const BoradMemberOrInsider = ({ data, setKycData, translator, kycFields }) => {
  const fieldKey = KycKeys.BOARD_MEMBERSHIP_EXISTING;
  const companyNameOneKey = KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_1;
  const companyNameTwoKey = KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_2;
  const companyNameThreeKey = KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_3;
  const { yesOrNo } = kycFields;

  const {
    [fieldKey]: { value, error },
  } = data;

  const boardMemberOrInsiderValue = convertBooleanToYesNo(value);
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));
  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: convertYesNoToBoolean(value),
      },
      [companyNameOneKey]: {
        ...prev[companyNameOneKey],
        error: "",
        value: "",
      },
      [companyNameTwoKey]: {
        ...prev[companyNameTwoKey],
        error: "",
        value: "",
      },
      [companyNameThreeKey]: {
        ...prev[companyNameThreeKey],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };
  return (
    <CustomSelect
      name={"boardMemberOrInsider"}
      inputOptions={options}
      label={translator("boardMemberOrInsiderLabel")}
      onChange={handleChange}
      value={boardMemberOrInsiderValue}
      errorMessage={error}
      isLabelOutsideInput
      placeholder={translator("selectYesNoOptions")}
    />
  );
};

BoradMemberOrInsider.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default BoradMemberOrInsider;
