import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { journeyTimeLineRoute } from "./routes.const";
import { useSelector } from "react-redux";

const KycGuardRoute = (props) => {
  const isKycSubmitted = useSelector((state) => state.general.kycSubmitted);

  if (isKycSubmitted) {
    return <Navigate to={journeyTimeLineRoute} />;
  }

  return <Outlet {...props} />;
};

export default KycGuardRoute;
