import documentsIcon from "../../assets/icons/documents-rounded-icon.svg";
import profileIcon from "../../assets/icons/rounded-profile-icon.svg";
import documentsSignedIcon from "../../assets/icons/documents-signed-rounded-icon.svg";
import doneIcon from "../../assets/icons/done-rounded-icon.svg";

export const IDENTITY_VERIFICATION_ID = "identity_verification";
export const COMPLETE_PROFILE_ID = "complete_profile";
export const REVIEW_SIGN_ID = "review_and_sign";
const journeyLocale = {
  identityVerification: "identityVerification",
  identityVerificationSubtitle: "identityVerificationSubtitle",
  completeYourProfile: "completeYourProfile",
  completeYourProfileSubtitle: "completeYourProfileSubtitle",
  reviewAndSignYourDocuments: "reviewAndSignYourDocuments",
  reviewAndSignYourDocumentsSubtitle: "reviewAndSignYourDocumentsSubtitle",
};
export const timeLineConfig = [
  {
    id: IDENTITY_VERIFICATION_ID,
    title: journeyLocale.identityVerification,
    subTitle: journeyLocale.identityVerificationSubtitle,
    icon: profileIcon,
    doneIcon: doneIcon,
    isDone: false,
  },
  {
    id: COMPLETE_PROFILE_ID,
    title: journeyLocale.completeYourProfile,
    subTitle: journeyLocale.completeYourProfileSubtitle,
    icon: documentsIcon,
    doneIcon: doneIcon,
    isDone: false,
  },
  {
    id: REVIEW_SIGN_ID,
    title: journeyLocale.reviewAndSignYourDocuments,
    subTitle: journeyLocale.reviewAndSignYourDocumentsSubtitle,
    icon: documentsSignedIcon,
    doneIcon: doneIcon,
    isDone: false,
  },
];
