import React from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";
import PropTypes from "prop-types";

const PoliticalPositionExisting = ({
  data,
  setKycData,
  translator,
  kycFields,
}) => {
  const fieldKey = KycKeys.POLITICAL_POSITION_EXISTING;
  const politicalPositionIsMeKey = KycKeys.POLITICAL_POSITION_IS_ME;
  const politicalPositionRoleKey = KycKeys.POLITICAL_POSITION_ROLE;
  const politicalPositionNameKey = KycKeys.POLITICAL_POSITION_NAME;
  const politicalPositionRelationKey = KycKeys.POLITICAL_POSITION_RELATIONSHIP;

  const {
    [fieldKey]: { value, error },
  } = data;
  const { yes_no: yesOrNo } = kycFields;
  const boardMemberOrInsiderValue = convertBooleanToYesNo(value);
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: convertYesNoToBoolean(value),
      },
      [politicalPositionIsMeKey]: {
        ...prev[politicalPositionIsMeKey],
        error: "",
        value: "",
      },
      [politicalPositionRoleKey]: {
        ...prev[politicalPositionRoleKey],
        error: "",
        value: "",
      },
      [politicalPositionNameKey]: {
        ...prev[politicalPositionNameKey],
        error: "",
        value: "",
      },
      [politicalPositionRelationKey]: {
        ...prev[politicalPositionRelationKey],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };
  return (
    <CustomSelect
      name={"areYouPep"}
      inputOptions={options}
      label={translator("areYouPep")}
      onChange={handleChange}
      value={boardMemberOrInsiderValue}
      errorMessage={error}
      isLabelOutsideInput
      placeholder={translator("selectYesNoOptions")}
    />
  );
};

PoliticalPositionExisting.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default PoliticalPositionExisting;
