import React from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import InputField from "../../../widgets/inputs/primary-input";

const House = ({ data, setData, translator }) => {
  const fieldKey = KycKeys.ADDRESS_HOUSE;
  const {
    [fieldKey]: { value, error, isDisabled },
  } = data;

  const validate = (value) => {
    const fieldErrorObject = KYCValidator.validateAddress(value);

    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = ({ target: { value } }) => {
    validate(value);
  };

  return (
    <InputField
      name={"house"}
      isDisabled={isDisabled}
      label={translator("house")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

House.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default House;
