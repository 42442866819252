import axiosInstance from "../../network/axios-instance";
import { serializeCustomerStatusResponse } from "../../serializer";
import { GetCustomerStatusUrl } from "./civil-id-details-urls.const";

export const getCustomerStatus = async (payload) => {
  try {
    const response = await axiosInstance.post(GetCustomerStatusUrl, payload);
    return serializeCustomerStatusResponse(response.data) || {};
  } catch (exception) {
    throw exception;
  }
};
