import React from "react";
import styles from "./menu-item.module.scss";

const MenuItem = ({ optionLabel, optionIcon, handleClick, testId }) => (
  <div className={styles.menuBox} onClick={handleClick} data-testid={testId}>
    <div className={styles.menuBoxContent}>
      <span className={styles.menuItemLabel}> {optionLabel}</span>
      <div> {optionIcon()}</div>
    </div>
  </div>
);

export default MenuItem;
