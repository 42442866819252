import axios from "axios";
import { apiUrl } from "../config";
import { defaultHeaders, defaultResponseType, defaultTimeout } from "./config";
import { getToken } from "../utils/auth";
import webOnBoardingStore from "../store";
import { setShowSessionExpiredModal } from "../store/slices/general.slice";

const axiosInstance = axios.create({
  baseURL: apiUrl,
  responseType: defaultResponseType,
  headers: defaultHeaders,
  timeout: defaultTimeout,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      webOnBoardingStore.dispatch(setShowSessionExpiredModal(true));
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
