import React, { useState, useRef, useEffect } from "react";
import styles from "./language-box.module.scss";
import arrowDownIcon from "../../assets/icons/arrow-small-down.svg";
import correctIcon from "../../assets/icons/correct-icon.svg";
import PropTypes from "prop-types";

const LanguageBox = ({
  isDisabled = false,
  onChange,
  value,
  errorMessage = "",
  inputOptions,
}) => {
  const [isActive, setIsActive] = useState(false);

  const dropdownRef = useRef(null);

  const selectedOptionObj = inputOptions?.find((item) => item.value === value);

  const toggleDropdown = () => {
    if (isDisabled) {
      return;
    }

    if (isActive) {
      setIsActive(false);
      return;
    }
    setIsActive(true);
  };

  const handleChange = (value) => {
    onChange(value);
    setIsActive(!isActive);
  };

  const dropdownOptions = inputOptions?.map((entry) =>
    entry.disabled ? (
      <></>
    ) : (
      <div key={entry.value} className={styles.selectedOptionContainer}>
        <div
          data-testid={entry.value}
          onClick={() => handleChange(entry.value)}
          className={`${styles.dropdownItem} ${value === entry.value ? styles.active : ""}`}
        >
          {entry.optionLabel}
        </div>
        {value === entry.value && (
          <img src={correctIcon} alt="Selected option icon" />
        )}
      </div>
    )
  );

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} ref={dropdownRef}>
      <div className={styles.customSelectContainer}>
        <div
          data-testid={"language select"}
          className={`${styles.dropdownButton} ${isActive ? styles.active : ""} 
            ${errorMessage ? styles.error : ""} ${isDisabled ? styles.disabled : ""}`}
          onClick={toggleDropdown}
        >
          <div className={styles.innerContent}>
            {value && (
              <span className={styles.dropdownValue}>
                {selectedOptionObj ? selectedOptionObj.label : ""}
              </span>
            )}
            <img
              className={`${styles.arrowIcon} ${isDisabled ? styles.disabled : ""}`}
              src={arrowDownIcon}
              alt="Arrow Down Icon"
            />
            <div />
          </div>
        </div>

        <div
          className={styles.dropdownContent}
          style={{ display: isActive ? "block" : "none" }}
        >
          {dropdownOptions}
        </div>
      </div>
      {errorMessage && (
        <span className={styles.errorMessage}>{errorMessage}</span>
      )}
    </div>
  );
};

LanguageBox.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  inputOptions: PropTypes.array.isRequired,
};

export default LanguageBox;
