import React from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";

const RiskToleranceLevel = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.RISK_TOLERANCE_LEVEL;

  const { riskToleranceLevels } = kycFields;
  const {
    [fieldKey]: { value, error },
  } = data;

  const riskToleranceLevelsOptions = Object.keys(riskToleranceLevels)?.map(
    (key) => ({
      label: riskToleranceLevels[key],
      value: key,
    })
  );

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };

  return (
    <CustomSelect
      name={"riskToleranceLevel"}
      label={translator("levelOfRiskToleranceLabel")}
      onChange={handleChange}
      value={value}
      inputOptions={riskToleranceLevelsOptions}
      errorMessage={error}
    />
  );
};

RiskToleranceLevel.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default RiskToleranceLevel;
