import { generalEnums } from "../../enums/general";
import KycKeys from "../../enums/kyc-enum";
import StepEnums from "../../enums/step-enum";

const kycFields = {
  [KycKeys.GENDER]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.FIRST_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.MIDDLE_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.FAMILY_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.BIRTH_DATE]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.NATIONALITY]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.OTHER_NATIONALITIES]: {
    value: [],
    isVisible: false,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.BIRTH_COUNTRY]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.CIVIL_ID_NUMBER]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: true,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.CIVIL_ID_SERIAL]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: true,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.CIVIL_ID_EXPIRY_DATE]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.PASSPORT_NUMBER]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.PASSPORT_COUNTRY]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.PASSPORT_EXPIRY_DATE]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.GUARDIANSHIP]: {
    value: false,
    isVisible: false,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.GUARDIAN_FULL_NAME]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "",
  },
  [KycKeys.GUARDIAN_NATIONALITY]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "",
  },
  [KycKeys.GUARDIAN_CIVIL_ID_NUMBER]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "",
  },
  [KycKeys.MOTHER_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.MOTHER_FIRST_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.MOTHER_MIDDLE_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.MOTHER_LAST_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.FATHER_FIRST_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.FATHER_MIDDLE_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.FATHER_LAST_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.MARRIAGE_STATUS]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.RELATIONSHIP_STATUS]: {
    value: "",
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.PARTNER_NAME]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.CHILDREN_NUMBER]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.CHILDREN_DETAILS]: {
    value: [],
    isVisible: false,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.CITY_TOWN_BIRTH]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isDisabled: false,
    step: StepEnums.PERSONAL_INFO_STEP,
  },
  [KycKeys.COUNTRY]: {
    value: "KW",
    isVisible: true,
    error: "",
    isDisabled: true,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.ADDRESS_CITY]: {
    value: "",
    isVisible: true,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.ADDRESS_AREA]: {
    value: "",
    isVisible: true,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.ADDRESS_BLOCK]: {
    value: "",
    isVisible: true,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.ADDRESS_STREET]: {
    value: "",
    isVisible: true,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.ADDRESS_HOUSE]: {
    value: "",
    isVisible: true,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.EMAIL]: {
    value: "",
    isVisible: false,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.COUNTRY_CODE]: {
    value: "+965",
    isVisible: true,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.CONTACT_MOBILE_NUMBER]: {
    value: "",
    isVisible: true,
    error: "",
    isDisabled: false,
    step: StepEnums.CONTACT_INFO_STEP,
  },
  [KycKeys.EMPLOYMENT_STATUS]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.LAST_EMPLOYMENT_STATUS]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.EMPLOYER_TYPE]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.PRIVATE_SECTOR_INDUSTRY]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.GOVERNMENT_ENTITY_TYPE]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.EMPLOYMENT_SECTOR]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.EMPLOYMENT_COMPANY]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.EMPLOYMENT_ENTITY_NAME]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.EMPLOYMENT_ORGANIZATION_NAME]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.EMPLOYMENT_DEPARTMENT]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.JOB_TITLE]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.BOARD_MEMBERSHIP_EXISTING]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_1]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    isValidated: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_2]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: true,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.BOARD_MEMBERSHIP_COMPANY_NAME_3]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: true,
    isValidated: false,
    step: StepEnums.EMPLOYMENT_INFO_STEP,
  },
  [KycKeys.ACCOUNT_PURPOSE]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.ACCOUNT_PURPOSE_OTHER]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INCOME_ANNUAL_V2]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INCOME_SOURCE_V2]: {
    value: [],
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INCOME_TYPE]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INCOME_DETAILS]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INVESTMENT_REASON]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INVESTMENT_LEVEL]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INVESTMENT_YEARS_EXPERIENCE]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.RISK_TOLERANCE_LEVEL]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.INVESTMENT_ADVISOR]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.PRIVATE_BUSINESS_INDUSTRY]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.PRIVATE_BUSINESS_SECTOR]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.PRIVATE_BUSINESS_COMPANY]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.PRIVATE_BUSINESS_JOB]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.PRIVATE_BUSINESS_SENIOR_EXECUTIVE]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.LIQUID_INVESTMENTS_KD]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.ASSETS_VALUE]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.TRANSACTIONS_VALUE_PAST_TWO_YEARS]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.FINANCIAL_SECTOR_YEARS_EXPERIENCE]: {
    value: "",
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_SOURCE]: {
    value: [],
    isVisible: true,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_AMOUNT_INHERITANCE]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_DETAILS_INHERITANCE]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_AMOUNT_JOB]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_AMOUNT_SAVING]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_DETAILS_SAVING]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_AMOUNT_SALE]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_DETAILS_SALE]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_AMOUNT_OTHER]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_DETAILS_OTHER]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_AMOUNT_INVESTMENTS]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.WEALTH_DETAILS_INVESTMENTS]: {
    value: "",
    isVisible: false,
    error: "",
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.POLITICAL_POSITION_EXISTING]: {
    value: "",
    isVisible: true,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.POLITICAL_POSITION_IS_ME]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.POLITICAL_POSITION_NAME]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.POLITICAL_POSITION_RELATIONSHIP]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.POLITICAL_POSITION_ROLE]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.US_CITIZEN]: {
    value: "",
    isVisible: true,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.TAX_PAYER_IDENTIFICATION]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.US_ADDRESS_1]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.US_ADDRESS_2]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: true,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.US_ADDRESS_3]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    isOptional: true,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY]: {
    value: "",
    isVisible: true,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.TAX_COUNTRY_1]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.TAX_PAYER_ID_1]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.TAX_COUNTRY_2]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.TAX_PAYER_ID_2]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.TAX_COUNTRY_3]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: true,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.TAX_PAYER_ID_3]: {
    value: "",
    isVisible: false,
    error: "",
    isOptional: true,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.IS_CUSTOMER_SAME_AS_BENEFICIARY]: {
    value: true,
    isVisible: true,
    error: "",
    isValidated: false,
    step: StepEnums.ADDITIONAL_INFO_STEP,
  },
  [KycKeys.BENEFICIARY_NAME]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_RELATIONSHIP]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_NATIONALITY]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_ADDRESS]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_CIVILID_PASSPORT_NUMBER]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_POWER_OF_ATTORNEY_ENABLED]: {
    value: false,
    isVisible: true,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_POWER_OF_ATTORNEY_NAME]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_POWER_OF_ATTORNEY_NATIONALITY]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.BENEFICIARY_POWER_OF_ATTORNEY_CIVIL_ID_NUMBER]: {
    value: "",
    isVisible: false,
    error: "",
    isValidated: false,
    step: "", //add step it belongs to,
  },
  [KycKeys.NBKC_CLIENT_CLASSIFICATION]: {
    value: "",
    error: "",
    isVisible: false,
    isValidated: false,
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.BANK_NAME]: {
    value: "",
    isVisible: true,
    error: "",
    isOptional: false,
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
  [KycKeys.IBAN_NUMBER]: {
    value: "",
    error: "",
    min: 1,
    max: 8,
    isDisabled: true,
    isVisible: true,
    isOptional: false,
    step: StepEnums.INCOME_AND_WEALTH_STEP,
  },
};

export default kycFields;
