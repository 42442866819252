import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import InputField from "../../../widgets/inputs/primary-input";
import { capitalizeFirstLetter } from "../../../utils";
import { getIbanValidation } from "../../../helpers/general-helpers";

const Iban = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.IBAN_NUMBER;
  const bankNameKey = KycKeys.BANK_NAME;

  const {
    [fieldKey]: { value, error, min, max, isDisabled },
    [bankNameKey]: { value: bankNameValue },
  } = data;

  const isIbanDisabled = !bankNameValue || !isDisabled;
  const validationData = { bankNameValue, min, max };

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateIbanNumber(
      value,
      validationData
    );
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: capitalizeFirstLetter(value),
      },
    }));
  };

  const handleChange = ({ target: { value } }) => {
    validateField(value);
  };
  useEffect(() => {
    if (bankNameValue) {
      const { min, max } = getIbanValidation(bankNameValue);
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          min: min,
          max: max,
        },
      }));
    }
  }, [bankNameValue]);

  return (
    <InputField
      name={"iban"}
      isDisabled={isIbanDisabled}
      label={translator("iban")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      minIbanLength={min}
      maxIbanLength={max}
    />
  );
};

Iban.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default Iban;
