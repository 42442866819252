import React, { useState, useEffect } from "react";
import styles from "./main-layout.module.scss";
import backArrow from "../../assets/icons/small-back-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowWidth from "../../custom-hooks/useWindonWidth";
import { getLanguage } from "../../utils";
import { languageEnum } from "../../enums/language-enum";
import {
  fullDisclosureUrlAr,
  fullDisclosureUrlEn,
  privacyPolicyUrlAr,
  privacyPolicyUrlEn,
} from "../../config/outside-urls";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const MainLayout = ({
  title,
  subTitle,
  children,
  footer,
  stepNumber,
  stepTitle,
  withAlertMessage,
  isContract,
  isAllStepsDone = false,
}) => {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const [isBackArrowVisible, setIsBackArrowVisible] = useState(false);
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    const isBackButtonVisible = location?.pathname !== "/" && !isAllStepsDone;
    setIsBackArrowVisible(isBackButtonVisible);
  }, [location, isAllStepsDone]);

  const getKycProgress = () => {
    switch (stepNumber) {
      case 1:
        return "20%";
      case 2:
        return "40%";
      case 3:
        return "60%";
      case 4:
        return "80%";
      case 5:
        return "100%";
    }
  };
  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    setDirection(dir);
  }, [i18n, i18n.language]);

  return (
    <div
      className={
        isContract
          ? styles.mainLayoutContract
          : withAlertMessage
            ? styles.mainLayoutWithAlert
            : styles.mainLayout
      }
    >
      <div className={styles.contentLayout}>
        {isBackArrowVisible && windowWidth > 700 ? (
          <div className={styles.topMenu}>
            <div
              className={styles.backContainer}
              onClick={() => {
                navigate(-1);
              }}
            >
              <img className={styles.iconStyle} src={backArrow} alt="back" />
              <span className={styles.backText}>{t("back")}</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={isContract ? styles.boxLayoutContract : styles.boxLayout}
        >
          <div
            className={styles.scrollableContent}
            style={{
              paddingBottom: footer ? "var(--large-spacing)" : "",
            }}
          >
            {stepNumber ? (
              <>
                <div className={styles.stepInfo}>
                  {t("stepLabel")}
                  <div className={styles.numStep}>
                    <span className={styles.stepNumber}>{stepNumber}</span>
                    {t("totalStepsNumber")}
                  </div>
                </div>
                <span className={styles.stepTitle}>{stepTitle}</span>
                <hr
                  className={styles.hr}
                  style={{
                    background:
                      direction === "ltr"
                        ? `linear-gradient(to right, #2D2D2D 0%, #2D2D2D ${getKycProgress()}, #F4F4F4 ${getKycProgress()}, #F4F4F4 100%)`
                        : `linear-gradient(to left, #2D2D2D 0%, #2D2D2D ${getKycProgress()}, #F4F4F4 ${getKycProgress()}, #F4F4F4 100%)`,
                  }}
                />
              </>
            ) : (
              <></>
            )}

            {title && (
              <span className={styles.mainTitle} data-testid={`test-${title}`}>
                {title}
              </span>
            )}
            {subTitle && <span className={styles.subtitle}>{subTitle}</span>}
            <div className={styles.container}> {children}</div>
          </div>
          {footer ? (
            <div className={styles.footer}>
              <div className={styles.footerInner}>{footer()}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {windowWidth > 700 ? (
          <>
            <div className={styles.bottomMenu}>
              <span
                className={styles.menuStyle}
                onClick={() => {
                  const language = getLanguage();
                  if (language === languageEnum.english) {
                    window.open(privacyPolicyUrlEn);
                  } else {
                    window.open(privacyPolicyUrlAr);
                  }
                }}
              >
                {t("privacyPolicy")}
              </span>
              <span
                className={styles.menuStyle}
                onClick={() => {
                  const language = getLanguage();
                  if (language === languageEnum.english) {
                    window.open(fullDisclosureUrlEn);
                  } else {
                    window.open(fullDisclosureUrlAr);
                  }
                }}
              >
                {t("fullDisclosure")}
              </span>
            </div>
            <div className={styles.contactSupport}>
              <div>
                <span>{t("furtherInformationSupport")}</span>
                <a href="tel:1803111">{t("internalSupportNumber")}</a>
              </div>
              <div>
                <span>{t("supportOutsideKuwait")}</span>
                <a href="tel:+96522246903">{t("supportNumberOutisdeKuwait")}</a>
              </div>
              <div>
                <span>{t("workingHours")}</span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.element,
  footer: PropTypes.func,
  stepNumber: PropTypes.number,
  stepTitle: PropTypes.string,
  withAlertMessage: PropTypes.string,
  isContract: PropTypes.bool,
  isAllStepsDone: PropTypes.bool,
};

export default MainLayout;
