import React from "react";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";

const CivilIdSerialNumber = ({ data, translator }) => {
  const fieldKey = KycKeys.CIVIL_ID_SERIAL;

  const {
    [fieldKey]: { value, error, isDisabled },
  } = data;

  return (
    <InputField
      label={translator("civilIdSerialNumber")}
      value={value}
      errorMessage={error}
      isDisabled={isDisabled}
    />
  );
};

CivilIdSerialNumber.propTypes = {
  data: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default CivilIdSerialNumber;
