import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import employerTypeEnums from "../../../enums/employer-type";
import PropTypes from "prop-types";

const EntityName = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.EMPLOYMENT_ENTITY_NAME;
  const employerTypeKey = KycKeys.EMPLOYER_TYPE;
  const entityTypeKey = KycKeys.GOVERNMENT_ENTITY_TYPE;
  const { entityNames } = kycFields;
  const {
    [fieldKey]: { value, error },
    [employerTypeKey]: { value: employerTypeValue },
    [entityTypeKey]: { value: entityTypeValue },
  } = data;

  const isVisible = employerTypeValue === employerTypeEnums.GOVERNMENT;

  const entityNameOptions = Object.keys(entityNames)?.map((key) => ({
    label: entityNames[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleDropDownChange = (value) => {
    validateField(value);
  };

  const handleInputChange = (e) => {
    validateField(e.target.value);
  };
  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return;
  }

  if (Object.keys(entityNames).length) {
    return (
      <CustomSelect
        name={"entityName"}
        inputOptions={entityNameOptions}
        label={translator("entityNameLabel")}
        isDisabled={!entityTypeValue}
        onChange={handleDropDownChange}
        value={value}
        errorMessage={error}
      />
    );
  }

  return (
    <InputField
      name={"entityName"}
      isDisabled={false}
      label={translator("entityNameLabel")}
      onChange={handleInputChange}
      value={value}
      errorMessage={error}
    />
  );
};

EntityName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default EntityName;
