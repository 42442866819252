import React, { useEffect, useState } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { getAreaByCityName } from "../../../helpers/general-helpers";
import CustomSelect from "../../../widgets/inputs/custom-select";
import { useTranslation } from "react-i18next";
import { languageEnum } from "../../../enums/language-enum";

const Area = ({ data, setData, cities, translator }) => {
  const { i18n } = useTranslation();

  const fieldKey = KycKeys.ADDRESS_AREA;
  const cityFieldKey = KycKeys.ADDRESS_CITY;
  const {
    [fieldKey]: { value, error, isDisabled },
    [cityFieldKey]: { value: cityFieldValue },
  } = data;
  const disabled = !cityFieldValue || isDisabled;
  const [areaLanguage, setAreaLanguage] = useState(languageEnum.english);
  const cityAreas = getAreaByCityName(cities, cityFieldValue);
  const areasOptions = cityAreas.map((area) => ({
    value: area[languageEnum.english],
    label: area[areaLanguage],
  }));

  const validate = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);

    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = (value) => {
    validate(value);
  };

  useEffect(() => {
    setAreaLanguage(i18n.language);
  }, [i18n, i18n.language]);

  return (
    <CustomSelect
      name={"area"}
      isDisabled={disabled}
      label={translator("area")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      inputOptions={areasOptions}
    />
  );
};

Area.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  cities: PropTypes.array.isRequired,
  translator: PropTypes.func.isRequired,
};

export default Area;
