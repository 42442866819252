import React, { useState, useRef, useEffect } from "react";
import styles from "./custom-multi-select.module.scss";
import arrowDownIcon from "../../../assets/icons/arrow-down.svg";
import correctIcon from "../../../assets/icons/correct-icon.svg";
import blackCloseIcon from "../../../assets/icons/black-close-icon.svg";
import PropTypes from "prop-types";

const CustomMultiSelect = ({
  name = "",
  isDisabled = false,
  label,
  onChange,
  errorMessage = "",
  inputOptions,
  defaultValues,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValues, setSelectedValues] = useState(defaultValues || []);

  useEffect(() => {
    setSelectedValues(defaultValues || []);
  }, [defaultValues]);

  const dropdownRef = useRef(null);

  const handleSelectChange = (event, value, isSelectContentVisible = true) => {
    event.stopPropagation();

    if (isDisabled) {
      return;
    }

    let updatedSelectedValues = [];
    const optionObj = inputOptions?.find((item) => item.value === value);

    if (optionObj && optionObj.isDisabled) {
      setIsActive(!isActive);
      return;
    }

    if (selectedValues.includes(value)) {
      updatedSelectedValues = selectedValues.filter((val) => val !== value);
    } else {
      updatedSelectedValues = [...selectedValues, value];
    }

    setSelectedValues(updatedSelectedValues);
    onChange(updatedSelectedValues);
    if (isSelectContentVisible) {
      setIsActive(!isActive);
    }
  };

  const filteredLabels = inputOptions
    .filter((obj) => defaultValues.includes(obj.value))
    .map((obj) => obj.label);

  const getOptionData = (label) => {
    const foundItem = inputOptions?.find((item) => item.label === label);
    return foundItem || null;
  };

  const toggleDropdown = () => {
    if (isDisabled) {
      return;
    }
    setIsActive(!isActive);
  };

  const dropdownOptions = inputOptions?.map((entry) => (
    <div key={entry.value} className={styles.selectedOptionContainer}>
      <div
        data-testid={`${name}${entry.value}`}
        onClick={(e) => handleSelectChange(e, entry.value)}
        className={`${styles.dropdownItem} 
          ${defaultValues.includes(entry.value) ? styles.active : ""} 
          ${entry.isDisabled ? styles.disabled : ""}`}
      >
        {entry.label}
      </div>
      {defaultValues.includes(entry.value) && (
        <img src={correctIcon} alt="Selected option icon" />
      )}
    </div>
  ));

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} ref={dropdownRef}>
      <div className={styles.customSelectContainer}>
        <div
          className={`${styles.dropdownButton} ${isActive ? styles.active : ""}
            ${errorMessage ? styles.error : ""} ${isDisabled ? styles.disabled : ""}`}
          onClick={toggleDropdown}
          data-testid={name}
        >
          <label
            className={`${styles.floatingLabel} ${defaultValues.length ? styles.active : ""} ${
              errorMessage ? styles.error : ""
            } ${isDisabled ? styles.disabled : ""}`}
          >
            {label}
          </label>

          <div
            className={styles.innerContent}
            style={{
              display: selectedValues.length > 0 ? "flex" : "block",
            }}
          >
            <div className={styles.optionsContainer}>
              {filteredLabels?.map((entry) => {
                const { value = "", isDisabled } = getOptionData(entry);

                return (
                  <div
                    key={entry}
                    className={`${styles.dropdownValue} ${isDisabled ? styles.disabled : ""}`}
                    onClick={(e) => handleSelectChange(e, value, false)}
                  >
                    <p>{entry}</p>
                    <img src={blackCloseIcon} alt="Remove" />
                  </div>
                );
              })}
            </div>

            <img
              className={`${styles.arrowIcon} ${isDisabled ? styles.disabled : ""}`}
              src={arrowDownIcon}
              alt="Arrow Down Icon"
              data-testid={`${name}${"arrow"}`}
            />
          </div>
        </div>

        <div
          className={styles.dropdownContent}
          style={{ display: isActive ? "block" : "none" }}
        >
          {dropdownOptions}
        </div>
      </div>
      {errorMessage && (
        <span className={styles.errorMessage}>{errorMessage}</span>
      )}
    </div>
  );
};

CustomMultiSelect.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  inputOptions: PropTypes.array.isRequired,
  defaultValues: PropTypes.array.isRequired,
  name: PropTypes.string,
};

export default CustomMultiSelect;
