import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { countriesEnums } from "../../../enums/general";
import CustomSelect from "../../../widgets/inputs/custom-select";

const TaxCountryOne = ({
  data,
  setKycData,
  countries,
  isKuwaityCitizen,
  translator,
}) => {
  const fieldKey = KycKeys.TAX_COUNTRY_1;
  const taxCountry2Key = KycKeys.TAX_COUNTRY_2;
  const taxCountry3Key = KycKeys.TAX_COUNTRY_3;
  const paysTaxesInOtherCountryKey = KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY;
  const usCitizenKey = KycKeys.US_CITIZEN;

  const {
    [fieldKey]: { value, error, isDisabled },
    [paysTaxesInOtherCountryKey]: { value: paysTaxesInOtherCountryValue },
    [usCitizenKey]: { value: usCitizenValue },
    [taxCountry2Key]: { value: taxCountry2 },
    [taxCountry3Key]: { value: taxCountry3 },
  } = data;

  const disabled = usCitizenValue || isKuwaityCitizen || isDisabled;
  const isVisible = paysTaxesInOtherCountryValue;

  const countriesOptions = countries?.map((country) => ({
    value: country["alpha-2"],
    label: country.name,
    disabled:
      (usCitizenValue && country["alpha-2"] === countriesEnums.UNITEDSTATES) ||
      country["alpha-2"] === taxCountry2 ||
      country["alpha-2"] === taxCountry3
        ? true
        : false,
  }));
  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    if (
      value &&
      (value["alpha-2"] === taxCountry2 || value["alpha-2"] === taxCountry3)
    ) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          error: translator("countryAlreadySelected"),
          value: "",
        },
      }));
      return;
    }
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  useEffect(() => {
    if (isKuwaityCitizen) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          value: countriesEnums.KUWAIT,
        },
      }));
      return;
    }

    if (usCitizenValue) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          value: countriesEnums.UNITEDSTATES,
        },
      }));
      return;
    }

    if (!isVisible) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          value: "",
        },
      }));
      return;
    }
  }, [isKuwaityCitizen, usCitizenValue]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"taxCountryOne"}
      isDisabled={disabled}
      inputOptions={countriesOptions}
      label={translator("taxCountryOne")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

TaxCountryOne.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  isKuwaityCitizen: PropTypes.bool.isRequired,
  translator: PropTypes.func.isRequired,
};

export default TaxCountryOne;
