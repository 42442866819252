import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";
import { brokerageOptions } from "../../../config";
import { brokerageEums } from "../../../enums/general";
import { useTranslation } from "react-i18next";
import featureFlags from "../../../core/flag-config";

const BrokerageType = ({ data, setData, isDisabled }) => {
  const { t } = useTranslation("common");
  const selectDisabled = isDisabled || !featureFlags.showUSMarketProduct;
  const brokrageOptions = brokerageOptions.map((brokerage) => ({
    value: brokerage.value,
    label: t(brokerage.label),
  }));

  const validate = (value) => {
    setData(value);
  };

  const handleChange = (value) => {
    validate(value);
  };

  useEffect(() => {
    if (!featureFlags.showUSMarketProduct) {
      setData(brokerageEums.REGIONAL_BROKERAGE_RBU);
    }
  }, []);

  return (
    <CustomSelect
      isDisabled={selectDisabled}
      name={t("product")}
      label={t("product")}
      onChange={handleChange}
      value={data}
      inputOptions={brokrageOptions}
    />
  );
};

BrokerageType.propTypes = {
  data: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default BrokerageType;
