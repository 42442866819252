import React from "react";
import styles from "./type-box.module.scss";
import PropTypes from "prop-types";

const TypeBox = ({
  id,
  title,
  subtitle,
  badgeTitle,
  selectedId,
  handleclick,
}) => (
  <div
    data-testid={id}
    className={`${styles.container} ${id === selectedId ? styles.active : ""}`}
    onClick={() => {
      handleclick(id);
    }}
  >
    <div className={styles.innerContainer}>
      <span className={styles.title}>{title}</span>
      {badgeTitle ? (
        <div className={styles.badgeContainer}>{badgeTitle}</div>
      ) : (
        <></>
      )}
    </div>
    <span className={styles.subtitle}>{subtitle}</span>
  </div>
);

export default TypeBox;

TypeBox.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  badgeTitle: PropTypes.string.isRequired,
  selectedId: PropTypes.string,
  handleclick: PropTypes.func.isRequired,
};
