import React from "react";
import styles from "./info-modal.module.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import useWindowWidth from "../../custom-hooks/useWindonWidth";
import mobileCloseIcon from "../../assets/icons/white-close-icon.svg";
import PropTypes from "prop-types";

const InfoModal = ({ title, subTitle, fieldInfoImage, handleCloseClick }) => {
  const windowWidth = useWindowWidth();

  return (
    <>
      {windowWidth > 700 ? (
        <div className={styles.overlay}>
          <div className={styles.content} onClick={handleCloseClick}>
            <div>
              <button className={styles.closeButton}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
            <div className={styles.container}>
              <span className={styles.title}>{title}</span>
              <span className={styles.subTitle}>{subTitle}</span>
            </div>

            <img
              src={fieldInfoImage}
              alt="CivilIDInfo"
              className={styles.imageStyle}
            />
          </div>
        </div>
      ) : (
        <div className={styles.overlay} onClick={handleCloseClick}>
          <div className={styles.mobileContainer}>
            <span className={styles.title}>{title}</span>
            <span className={styles.subTitle}>{subTitle}</span>
            <img
              src={fieldInfoImage}
              className={styles.imageStyle}
              alt="CivilIDInfo"
            />
          </div>
          <button className={styles.closeButtonMobile}>
            <img src={mobileCloseIcon} alt="Close" />
          </button>
        </div>
      )}
    </>
  );
};

InfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  fieldInfoImage: PropTypes.string.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
};

export default InfoModal;
