import React, { useState, useEffect } from "react";
import styles from "./overlay.module.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import useWindowWidth from "../../custom-hooks/useWindonWidth";
import mobileCloseIcon from "../../assets/icons/white-close-icon.svg";
import { useTranslation } from "react-i18next";
import "../../../src/theme/spacing.scss";

const OverlayModal = ({ title, fieldInfoImage, handleCloseClick }) => {
  const windowWidth = useWindowWidth();
  const { i18n } = useTranslation("common");
  const [direction, setDirection] = useState("rtl");

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    setDirection(dir);
  }, [i18n, i18n.language]);

  const getDirection = () =>
    direction === "ltr"
      ? {
          right: "var(--extra-large-spacing)",
        }
      : {
          left: "var(--extra-large-spacing)",
        };

  return (
    <>
      {windowWidth > 700 ? (
        <div className={styles.overlay}>
          <div className={styles.content} onClick={handleCloseClick}>
            <div className={styles.container}>
              <span className={styles.title}>{title}</span>
              <button
                className={styles.closeButton}
                style={getDirection()}
                data-testid="closeIcon"
              >
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
            <div>
              <img
                src={fieldInfoImage}
                alt="CivilIDInfo"
                className={styles.imageStyle}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.overlay} onClick={handleCloseClick}>
          <img src={fieldInfoImage} alt="CivilIDInfo" />
          <button className={styles.closeButtonMobile}>
            <img src={mobileCloseIcon} alt="Close" />
          </button>
        </div>
      )}
    </>
  );
};

export default OverlayModal;
