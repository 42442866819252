import React from "react";
import successIcon from "../../assets/icons/sucess-icon.svg";
import { useTranslation } from "react-i18next";
import InfoContainer from "../../widgets/info-container";
import PrimaryButton from "../../widgets/buttons/primary-button";
import MainLayout from "../../layouts/main-layout";
import { journeyTimeLineRoute } from "../../routes/routes.const";
import { useNavigate } from "react-router-dom";

const ApplicationSubmitted = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const renderFooter = () => {
    const onContinue = () => {
      navigate(journeyTimeLineRoute, {
        replace: true,
      });
    };
    return (
      <PrimaryButton onClick={onContinue} data-testid="done">
        {t("doneLabel")}
      </PrimaryButton>
    );
  };

  return (
    <MainLayout footer={renderFooter}>
      <InfoContainer
        icon={successIcon}
        title={t("applicationSubmittedTitle")}
        subTitle={t("applicationSubmittedSubTitle")}
      />
    </MainLayout>
  );
};

export default ApplicationSubmitted;
