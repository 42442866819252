import { bankENums } from "../enums/income-and-wealth";
import KycKeys from "../enums/kyc-enum";

const getAreaByCityName = (cities, cityName) => {
  if (!cities || !cityName) {
    return [];
  }

  const foundCity = cities.find((cityObj) => {
    const {
      name: { en: city },
    } = cityObj;
    return city === cityName;
  });

  if (!foundCity) {
    return [];
  }

  return foundCity.areas || [];
};

const filterObjectByKey = (objectTobeFiltered, keyToFilter, filterValue) => {
  const filteredObjects = Object.keys(objectTobeFiltered)
    .filter((key) => objectTobeFiltered[key][keyToFilter] === filterValue)
    .reduce((obj, key) => {
      obj[key] = objectTobeFiltered[key];
      return obj;
    }, {});
  return filteredObjects;
};
export { getAreaByCityName, filterObjectByKey };

export const getCountryByAlpha2 = (countries, alpha2Code) =>
  countries.find((country) => country["alpha-2"] === alpha2Code) || "";

export const createChildrenDetailsList = (childrenNumber) => {
  const resultArray = [];

  for (let i = 1; i <= childrenNumber; i++) {
    resultArray.push({
      name: `${KycKeys.CHILDREN_NAME}_${i}`,
      value: "",
      error: "",
    });
  }

  return resultArray;
};

export const childrenFullNameDynamicLabel = (counter) =>
  `Child full name (${counter})`;

export function isStepDoneById(array, id) {
  return array.some((task) => task.id === id && task.isDone);
}

export function areAllStepsDone(array) {
  return array.every((step) => step.isDone);
}

export function getIbanValidation(bankName) {
  if (!bankName) {
    return;
  }

  if (bankName === bankENums.NATIONAL_BANK_KUWAIT) {
    return { min: 10, max: 34 };
  } else if (bankName === bankENums.NATIONAL_BANK_KUWAIT_INTERNATIONAL) {
    return { min: 8, max: 34 };
  } else {
    return { min: 5, max: 34 };
  }
}

export const parseMessageWithUrls = (message) => {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const parts = message.split(urlPattern);

  return parts.map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit" }}
        >
          {part}
        </a>
      );
    } else {
      return part;
    }
  });
};
