import React from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";

const BankName = ({ data, setKycData, kycFieldsOptions, translator }) => {
  const fieldKey = KycKeys.BANK_NAME;
  const ibanNumberFieldKey = KycKeys.IBAN_NUMBER;
  const { bank_names: bankNamesList } = kycFieldsOptions;
  const {
    [fieldKey]: { value, error, isVisible },
  } = data;
  const items = Object.entries(bankNamesList)?.map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
      [ibanNumberFieldKey]: {
        ...prev[ibanNumberFieldKey],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"bankName"}
      inputOptions={items}
      label={translator("bankName")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

BankName.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFieldsOptions: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default BankName;
