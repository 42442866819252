import React from "react";
import styles from "./circular-loader.module.scss";

const CircularLoader = () => (
  <div className={styles.spinnerContainer} data-testid="spinner-container">
    <div className={styles.spinner} data-testid="spinner" />
  </div>
);

export default CircularLoader;
