import KycKeys from "../../enums/kyc-enum";

const emailAddressDetailsData = {
  [KycKeys.EMAIL]: {
    value: "",
    error: "",
  },

  [KycKeys.EMAIL_CONFIRM]: { value: "", error: "" },
};

export default emailAddressDetailsData;
