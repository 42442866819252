import React, { useEffect } from "react";
import InputField from "../../../widgets/inputs/primary-input";
import FieldsValidation from "../../../validation/fields-validation";
import KycKeys from "../../../enums/kyc-enum";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ConfirmEmailAddressField = ({ data, setData }) => {
  const { t } = useTranslation("common");
  const fieldKey = KycKeys.EMAIL_CONFIRM;
  const { confirmEmailAddress } = useSelector(
    (state) => state.general.userInfo
  );
  const {
    [KycKeys.EMAIL]: { value: emailAddress, error: emailError },
    [fieldKey]: { value: confirmEmailAddressValue, error: confirmEmailError },
  } = data;

  const validateField = (value) => {
    const fieldErrorObject = FieldsValidation.validateConfirmEmailAddress(
      value,
      emailAddress,
      emailError
    );
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value: confirmEmailAddress,
      },
    }));
  }, []);

  return (
    <>
      <InputField
        isDisabled={false}
        label={t("confirmEmailLabel")}
        onChange={handleChange}
        value={confirmEmailAddressValue}
        errorMessage={confirmEmailError}
        name="confirmEmail"
      />
    </>
  );
};
export default ConfirmEmailAddressField;
