import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PrimaryButton from "../../widgets/buttons/primary-button";
import FirstName from "./components/first-name";
import FamilyName from "./components/family-name";
import MiddleName from "./components/middle-name";
import MotherFirstName from "./components/mother-first-name";
import MotherMiddleName from "./components/mother-middle-name";
import MotherLastName from "./components/mother-last-name";
import KycKeys from "../../enums/kyc-enum";
import kycFields from "../../config/kyc";
import StepEnums from "../../enums/step-enum";
import Gender from "./components/gender";
import DateOfBirth from "./components/dob";
import Nationality from "./components/nationality";
import PassportNumber from "./components/passport-number";
import PassportCountry from "./components/passport-country";
import PassportExpiryDate from "./components/passport-expiry-date";
import BirthCountry from "./components/birth-country";
import CityTownBirth from "./components/city-town-birth";
import CivilIdNumber from "./components/civil-id-number";
import CivilIdSerialNumber from "./components/civil-id-serial-number";
import CivilIdExpiryDate from "./components/civil-id-expiry-date";
import FatherMiddleName from "./components/father-middle-name";
import FatherLastName from "./components/father-last-name";
import FatherFirstName from "./components/father-first-name";
import MaritalStatus from "./components/marital-status";
import SpouseName from "./components/spouse-name";
import ChildrenNumber from "./components/children-number";
import ChildrenDetails from "./components/children-details";
import OtherNationalities from "./components/other-nationalities";
import { contactInfoStepRoute } from "../../routes/routes.const";
import {
  getKycData,
  putKycData,
  getCustomer,
} from "../../general-services.proxy";
import SkeletonLoader from "../../widgets/fields-skeleton";
import MainLayout from "../../layouts/main-layout";
import { scrollToTop } from "../../utils";
import { useTranslation } from "react-i18next";

const PersonalInfoStep = () => {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
  const stepName = StepEnums.PERSONAL_INFO_STEP;
  const [kycData, setKycData] = useState(kycFields);
  const [isLoading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderKycId, setrderKycId] = useState("");

  const { kycFields: kycFieldsOptions, countries } = useSelector(
    (state) => state.general
  );

  const {
    gender: genderOptions = {},
    relationship_status: relationshipStatus = {},
  } = kycFieldsOptions;

  const childrenNumber = kycData[KycKeys.CHILDREN_NUMBER].value;
  const childrenDetailsData = kycData[KycKeys.CHILDREN_DETAILS].value;

  const childrenDetailsDataFilled = childrenDetailsData.every(
    (item) => item.value !== "" && !item.error
  );

  const isContinueDisabled = Object.keys(kycData)
    .filter(
      (key) =>
        kycData[key].step === stepName &&
        kycData[key].isVisible &&
        !kycData[key].isDisabled
    )
    .some(
      (key) =>
        (!kycData[key].value &&
          kycData[key].value !== false &&
          kycData[key].value !== 0) ||
        (Number(childrenNumber) > 0 && !childrenDetailsDataFilled) ||
        kycData[key].error
    );

  const handleNavigation = () => {
    navigate({
      pathname: contactInfoStepRoute,
    });
  };

  const saveKycData = async () => {
    try {
      setLoading(true);
      await putKycData(orderId, kycData, StepEnums.PERSONAL_INFO_STEP);
      handleNavigation();
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleNextActionClick = () => {
    saveKycData();
  };

  const fetchCustomerData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId } = await getCustomer();
      setrderKycId(orderKycId);
      setOrderId(orderId);
      const data = await getKycData(orderId, orderKycId);
      setKycData(data);
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  const renderFooter = () => (
    <PrimaryButton
      onClick={() => {
        handleNextActionClick();
      }}
      disabled={isContinueDisabled || isLoading}
      data-testid={"saveandcontinue"}
    >
      {t("saveAndContinueButton")}
    </PrimaryButton>
  );

  useEffect(() => {
    fetchCustomerData();
    return () => {
      setKycData(kycFields);
    };
  }, []);

  return (
    <MainLayout
      stepTitle={t("personalInfoStepTitle")}
      footer={renderFooter}
      stepNumber={1}
    >
      {isLoading ? (
        <SkeletonLoader numberOfFields={15} />
      ) : (
        <>
          <Gender
            data={{
              [KycKeys.GENDER]: kycData[KycKeys.GENDER],
            }}
            setKycData={setKycData}
            kycFieldOptions={{
              genderOptions: genderOptions,
            }}
            translator={t}
          />
          <FirstName
            data={{
              [KycKeys.FIRST_NAME]: kycData[KycKeys.FIRST_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MiddleName
            data={{
              [KycKeys.MIDDLE_NAME]: kycData[KycKeys.MIDDLE_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FamilyName
            data={{
              [KycKeys.FAMILY_NAME]: kycData[KycKeys.FAMILY_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <DateOfBirth
            data={{
              [KycKeys.BIRTH_DATE]: kycData[KycKeys.BIRTH_DATE],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <Nationality
            data={{
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY],
              [KycKeys.OTHER_NATIONALITIES]:
                kycData[KycKeys.OTHER_NATIONALITIES],
            }}
            setKycData={setKycData}
            countries={countries}
            translator={t}
          />
          <OtherNationalities
            data={{
              [KycKeys.OTHER_NATIONALITIES]:
                kycData[KycKeys.OTHER_NATIONALITIES],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY],
            }}
            setKycData={setKycData}
            countries={countries}
            translator={t}
          />
          <PassportNumber
            data={{
              [KycKeys.PASSPORT_NUMBER]: kycData[KycKeys.PASSPORT_NUMBER],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <PassportCountry
            data={{
              [KycKeys.PASSPORT_COUNTRY]: kycData[KycKeys.PASSPORT_COUNTRY],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY],
            }}
            setKycData={setKycData}
            translator={t}
            countries={countries}
          />
          <PassportExpiryDate
            data={{
              [KycKeys.PASSPORT_EXPIRY_DATE]:
                kycData[KycKeys.PASSPORT_EXPIRY_DATE],
              [KycKeys.NATIONALITY]: kycData[KycKeys.NATIONALITY],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <BirthCountry
            data={{
              [KycKeys.BIRTH_COUNTRY]: kycData[KycKeys.BIRTH_COUNTRY],
            }}
            setKycData={setKycData}
            translator={t}
            countries={countries}
          />
          <CityTownBirth
            data={{
              [KycKeys.CITY_TOWN_BIRTH]: kycData[KycKeys.CITY_TOWN_BIRTH],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <CivilIdNumber
            data={{
              [KycKeys.CIVIL_ID_NUMBER]: kycData[KycKeys.CIVIL_ID_NUMBER],
            }}
            translator={t}
          />
          <CivilIdSerialNumber
            data={{
              [KycKeys.CIVIL_ID_SERIAL]: kycData[KycKeys.CIVIL_ID_SERIAL],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <CivilIdExpiryDate
            data={{
              [KycKeys.CIVIL_ID_EXPIRY_DATE]:
                kycData[KycKeys.CIVIL_ID_EXPIRY_DATE],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FatherFirstName
            data={{
              [KycKeys.FATHER_FIRST_NAME]: kycData[KycKeys.FATHER_FIRST_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FatherMiddleName
            data={{
              [KycKeys.FATHER_MIDDLE_NAME]: kycData[KycKeys.FATHER_MIDDLE_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <FatherLastName
            data={{
              [KycKeys.FATHER_LAST_NAME]: kycData[KycKeys.FATHER_LAST_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MotherFirstName
            data={{
              [KycKeys.MOTHER_FIRST_NAME]: kycData[KycKeys.MOTHER_FIRST_NAME],
              [KycKeys.MOTHER_NAME]: kycData[KycKeys.MOTHER_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MotherMiddleName
            data={{
              [KycKeys.MOTHER_MIDDLE_NAME]: kycData[KycKeys.MOTHER_MIDDLE_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MotherLastName
            data={{
              [KycKeys.MOTHER_LAST_NAME]: kycData[KycKeys.MOTHER_LAST_NAME],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <MaritalStatus
            data={{
              [KycKeys.MARRIAGE_STATUS]: kycData[KycKeys.MARRIAGE_STATUS],
              [KycKeys.RELATIONSHIP_STATUS]:
                kycData[KycKeys.RELATIONSHIP_STATUS],
            }}
            setKycData={setKycData}
            translator={t}
            kycFieldOptions={{
              relationshipStatus: relationshipStatus,
            }}
          />
          <SpouseName
            data={{
              [KycKeys.PARTNER_NAME]: kycData[KycKeys.PARTNER_NAME],
              [KycKeys.MARRIAGE_STATUS]: kycData[KycKeys.MARRIAGE_STATUS],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <ChildrenNumber
            data={{
              [KycKeys.CHILDREN_NUMBER]: kycData[KycKeys.CHILDREN_NUMBER],
            }}
            setKycData={setKycData}
            translator={t}
          />
          <ChildrenDetails
            data={{
              [KycKeys.CHILDREN_DETAILS]: kycData[KycKeys.CHILDREN_DETAILS],
              [KycKeys.CHILDREN_NUMBER]: kycData[KycKeys.CHILDREN_NUMBER],
            }}
            setKycData={setKycData}
            translator={t}
          />
        </>
      )}
    </MainLayout>
  );
};

export default PersonalInfoStep;
