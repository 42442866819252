import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import employerTypeEnums from "../../../enums/employer-type";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";
import PropTypes from "prop-types";

const SeniorLeadershipPosition = ({
  data,
  setKycData,
  translator,
  kycFields,
}) => {
  const fieldKey = KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS;
  const employerTypeKey = KycKeys.EMPLOYER_TYPE;
  const {
    [fieldKey]: { value, error },
    [employerTypeKey]: { value: employerTypeValue },
  } = data;
  const { yesOrNo } = kycFields;

  const seniorLeadershipPosition = convertBooleanToYesNo(value);
  const isVisible = employerTypeValue === employerTypeEnums.GOVERNMENT;
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));
  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: convertYesNoToBoolean(value),
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };
  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return;
  }

  return (
    <CustomSelect
      name={"seniorLeadershipPosition"}
      inputOptions={options}
      label={translator("seniorLeadershipPositionLabel")}
      onChange={handleChange}
      value={seniorLeadershipPosition}
      errorMessage={error}
      isLabelOutsideInput
      placeholder={translator("selectYesNoOptions")}
    />
  );
};

SeniorLeadershipPosition.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default SeniorLeadershipPosition;
