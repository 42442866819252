import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import employerTypeEnums from "../../../enums/employer-type";
import PropTypes from "prop-types";

const EntityType = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.GOVERNMENT_ENTITY_TYPE;
  const entityNameKey = KycKeys.EMPLOYMENT_ENTITY_NAME;
  const employerTypeKey = KycKeys.EMPLOYER_TYPE;
  const { entityTypes } = kycFields;
  const {
    [fieldKey]: { value, error },
    [employerTypeKey]: { value: employerTypeValue },
  } = data;

  const isVisible = employerTypeValue === employerTypeEnums.GOVERNMENT;

  const entityTypesOptions = Object.keys(entityTypes)?.map((key) => ({
    label: entityTypes[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
      [entityNameKey]: {
        ...prev[entityNameKey],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (changedVal) => {
    if (value !== changedVal) {
      validateField(changedVal);
    }
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return;
  }

  return (
    <CustomSelect
      name={"entityType"}
      inputOptions={entityTypesOptions}
      label={translator("entityTypeLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

EntityType.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default EntityType;
