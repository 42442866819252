import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import InputField from "../../../widgets/inputs/primary-input";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { capitalizeWords } from "../../../utils";

const ChildrenDetails = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.CHILDREN_DETAILS;
  const childrenNumberKey = KycKeys.CHILDREN_NUMBER;
  const childrenFullNameKey = KycKeys.CHILDREN_NAME;

  const {
    [fieldKey]: { value: childrensNameList },
    [childrenNumberKey]: { value: childrenNumberValue },
  } = data;

  const isVisible = childrenNumberValue > 0;

  const validateField = (name, value, childrensList) => {
    const fieldErrorObject = KYCValidator.validateNames(value);
    const child = childrensList.find((child) => child.name === name);

    child.value = capitalizeWords(value);
    if (fieldErrorObject.errorMessage) {
      child.error = fieldErrorObject.errorMessage;
    } else {
      delete child.error;
    }

    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value: childrensList,
      },
    }));
  };

  const handleChange = ({ target: { value, name } }) => {
    const capitalizedFullName = capitalizeWords(value);

    const updatedChildrenList = childrensNameList.map((item) =>
      item.name === name ? { ...item, value: capitalizedFullName } : item
    );

    const existingItemIndex = updatedChildrenList.findIndex(
      (item) => item.name === name
    );

    if (existingItemIndex === -1) {
      updatedChildrenList.push({
        name,
        value: capitalizedFullName,
      });
    }

    validateField(name, value, updatedChildrenList);
  };

  const renderDynamicFields = () =>
    Array.from({ length: childrenNumberValue }, (_, index) => {
      const counter = index + 1;
      const dynamicFieldKey = `${childrenFullNameKey}_${counter}`;
      const child = childrensNameList.find(
        (child) => child.name === dynamicFieldKey
      );
      const childValue = child?.value || "";
      const fieldError = child?.error || "";

      return (
        <InputField
          name={dynamicFieldKey}
          key={counter}
          label={`${translator("childFullName")} ${counter}`}
          onChange={handleChange}
          value={childValue}
          errorMessage={fieldError}
        />
      );
    });

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return renderDynamicFields();
};

ChildrenDetails.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default ChildrenDetails;
