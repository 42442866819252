import React, { useEffect } from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";
import { generalEnums } from "../../../enums/general";

const SeniorExecutiveOrOwner = ({
  data,
  setKycData,
  translator,
  kycFields,
}) => {
  const fieldKey = KycKeys.PRIVATE_BUSINESS_SENIOR_EXECUTIVE;
  const sameEmploymentDetailsKey =
    KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS;

  const {
    [fieldKey]: { value, error },
    [sameEmploymentDetailsKey]: { value: sameBusinessDetailsVal },
  } = data;
  const { yesOrNo } = kycFields;

  const seniorExecutiveOrOwnerValue = convertBooleanToYesNo(value);
  const isVisible =
    convertBooleanToYesNo(sameBusinessDetailsVal) === generalEnums.NO;
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));
  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: errorMessageReceived,
        value: convertYesNoToBoolean(value),
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"seniorExecutiveOrOwner"}
      label={translator("seniorExecutiveOrOwnerLabel")}
      onChange={handleChange}
      value={seniorExecutiveOrOwnerValue}
      inputOptions={options}
      errorMessage={error}
      isLabelOutsideInput
      placeholder={translator("selectYesNoOptions")}
    />
  );
};

SeniorExecutiveOrOwner.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};
export default SeniorExecutiveOrOwner;
