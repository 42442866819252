import React, { useEffect } from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";
import { incomeSourceEnums } from "../../../enums/income-and-wealth";

const SameBusinessDetails = ({ data, setKycData, translator, kycFields }) => {
  const fieldKey = KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS;
  const businessSectorKey = KycKeys.PRIVATE_BUSINESS_INDUSTRY;
  const incomeSourceKey = KycKeys.INCOME_SOURCE_V2;
  const businessTypeKey = KycKeys.PRIVATE_BUSINESS_SECTOR;
  const privateBusinessNameKey = KycKeys.PRIVATE_BUSINESS_COMPANY;
  const privateBusinessSeniorExecutiveKey =
    KycKeys.PRIVATE_BUSINESS_SENIOR_EXECUTIVE;
  const privateBusinessJobKey = KycKeys.PRIVATE_BUSINESS_JOB;

  const {
    [fieldKey]: { value, error },
    [incomeSourceKey]: { value: incomeSourceValue },
  } = data;
  const { yesOrNo } = kycFields;

  const sameBusinessDetailsValue = convertBooleanToYesNo(value);

  const isVisible = incomeSourceValue.some(
    (obj) => obj === incomeSourceEnums.PRIVATE_BUSINESS
  );
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));
  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value: convertYesNoToBoolean(value),
        error: errorMessageReceived,
      },
      [businessSectorKey]: {
        ...prev[businessSectorKey],
        error: "",
        value: "",
      },
      [businessTypeKey]: {
        ...prev[businessTypeKey],
        error: "",
        value: "",
      },
      [privateBusinessNameKey]: {
        ...prev[privateBusinessNameKey],
        error: "",
        value: "",
      },
      [privateBusinessJobKey]: {
        ...prev[privateBusinessJobKey],
        error: "",
        value: "",
      },
      [privateBusinessSeniorExecutiveKey]: {
        ...prev[privateBusinessSeniorExecutiveKey],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"sameBusinessDetails"}
      label={translator("sameBusinessDetailsLabel")}
      onChange={handleChange}
      value={sameBusinessDetailsValue}
      inputOptions={options}
      errorMessage={error}
      isLabelOutsideInput
      placeholder={translator("selectYesNoOptions")}
    />
  );
};

SameBusinessDetails.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};
export default SameBusinessDetails;
