import axiosInstance from "./network/axios-instance";
import {
  PostCustomerUrl,
  PostCustomerOrderUrl,
  GetCustomerUrl,
  GetCustomerOrderUrl,
  GetCountriesAndCitiesUrl,
  GetCustomerOrderKycUrl,
  GetKycFieldsUrl,
  PutCustomerOrderKycUrl,
  PutCutomerOrderBrokerage,
  PutEmailAndLanguage,
  AuthorizeCustomer,
} from "./general-urls.const";
import {
  deserializeKycData,
  serializeCountriesAndCitiesResponse,
  serializeKycData,
  serializeCustomerResponse,
  serializeCustomerOrderResponse,
  serializePostCustomerResponse,
} from "./serializer";
import { getToken } from "./utils/auth";

export const postCustomer = async (payload) => {
  try {
    const response = await axiosInstance.post(PostCustomerUrl, payload);
    return serializePostCustomerResponse(response.data) || {};
  } catch (exception) {
    throw new Error("posting customer failed");
  }
};

export const postCustomerOrder = async (payload) => {
  try {
    const response = await axiosInstance.post(PostCustomerOrderUrl, payload);
    return response.data || {};
  } catch (exception) {
    throw new Error("posting customer order failed!");
  }
};

export const getCustomer = async () => {
  try {
    const response = await axiosInstance.get(GetCustomerUrl);
    return serializeCustomerResponse(response.data) || {};
  } catch (exception) {
    throw exception;
  }
};

export const getCustomerOrder = async (orderId) => {
  try {
    const response = await axiosInstance.get(GetCustomerOrderUrl(orderId));
    return serializeCustomerOrderResponse(response.data) || {};
  } catch (exception) {
    throw new Error("Fetching customer order data failed!");
  }
};

export const putCustomerOrder = async (orderId) => {
  try {
    const response = await axiosInstance.put(GetCustomerOrderUrl(orderId));
    return response.data || {};
  } catch (exception) {
    throw new Error("submitting kyc!");
  }
};

export const getCountriesAndCities = async () => {
  try {
    const response = await axiosInstance.get(GetCountriesAndCitiesUrl);
    return (
      serializeCountriesAndCitiesResponse(response.data) || {
        cities: [],
        countriesAr: [],
        countriesEn: [],
      }
    );
  } catch (exception) {
    throw new Error("Fetching countries and cities data failed!");
  }
};

export const getKycFields = async () => {
  try {
    const response = await axiosInstance.get(GetKycFieldsUrl);
    return response.data || {};
  } catch (exception) {
    throw new Error("Fetching kyc fields data failed!");
  }
};

export const putKycData = async (orderId, payload, category) => {
  try {
    const deserialzedData = deserializeKycData(payload, category);
    const response = await axiosInstance.put(
      PutCustomerOrderKycUrl(orderId),
      deserialzedData
    );

    return response || {};
  } catch (exception) {
    throw new Error("Updating kyc data failed!");
  }
};

export const getKycData = async (orderId, kycOrderId) => {
  try {
    const response = await axiosInstance.get(
      GetCustomerOrderKycUrl(orderId, kycOrderId)
    );
    const serializedData = serializeKycData(response.data);
    return serializedData || {};
  } catch (exception) {
    throw new Error("Fetching kyc data failed!");
  }
};

export const putCustomerOrderBrokerage = async (
  orderId,
  brokerageId,
  updateCustomerOrderPayload
) => {
  try {
    const response = await axiosInstance.put(
      PutCutomerOrderBrokerage(orderId, brokerageId),
      updateCustomerOrderPayload
    );
    return response.data || {};
  } catch (exception) {
    throw new Error("Updating customer order brokerage failed!");
  }
};

export const putEmail = async (email) => {
  try {
    const response = await axiosInstance.put(PutEmailAndLanguage, {
      email: email,
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error("Updating kyc data failed!");
  }
};

export const putLanguage = async (language) => {
  try {
    const response = await axiosInstance.put(PutEmailAndLanguage, {
      language: language,
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error("Updating kyc data failed!");
  }
};

export const putCivilSerialNumberAndLanguage = async (
  serialNumber,
  language
) => {
  try {
    const response = await axiosInstance.put(PutEmailAndLanguage, {
      civil_id_serial: serialNumber,
      language: language,
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error("Updating serial and language failed!");
  }
};

export const authorizeCustomer = async (civilId) => {
  try {
    const response = await axiosInstance.post(AuthorizeCustomer, {
      civil_id_number: civilId,
      access_token: getToken(),
    });
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (exception) {
    throw new Error("Authorize client failed!");
  }
};
