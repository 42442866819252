import { FRONT_CIVIL_ID, BACK_CIVIL_ID, CIVIL_ID_SELFIE } from "./config";
import { uploadedFileValidations } from "../../utils/validation/validation-messages";

export const validateUploadedFile = (
  file = "",
  maxSizeInBytes = null,
  allowedFormats = []
) => {
  if (!file) {
    return {
      isValid: false,
      error: uploadedFileValidations.noFileSelected,
    };
  }

  if (!allowedFormats.includes(file.type)) {
    return {
      valid: false,
      error: uploadedFileValidations.unSupportedUploadedFileFormat,
    };
  } else if (file.size > maxSizeInBytes) {
    return {
      isValid: false,
      error: uploadedFileValidations.exceedsSizeLimit,
    };
  } else {
    return {
      isValid: true,
      error: "",
    };
  }
};

export const getNextFileId = (fileId) => {
  if (fileId === FRONT_CIVIL_ID) {
    return BACK_CIVIL_ID;
  } else if (fileId === BACK_CIVIL_ID) {
    return CIVIL_ID_SELFIE;
  }
};

export const getAcceptedFileTypesById = (data = [], fileId) => {
  const item = data.find((obj) => obj.id === fileId);

  return item ? item.fileFormats : null;
};
