import React, { useState, useEffect } from "react";
import PrimaryButton from "../../widgets/buttons/primary-button";
import kycFields from "../../config/kyc";
import Country from "./components/country";
import KycKeys from "../../enums/kyc-enum";
import { useSelector } from "react-redux";
import City from "./components/city";
import Area from "./components/area";
import Block from "./components/block";
import Street from "./components/street";
import House from "./components/house";
import Email from "./components/email";
import StepEnums from "../../enums/step-enum";
import {
  getKycData,
  putKycData,
  getCustomer,
} from "../../general-services.proxy";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "../../widgets/fields-skeleton";
import { employmentInfoStepRoute } from "../../routes/routes.const";
import MainLayout from "../../layouts/main-layout";
import { scrollToTop } from "../../utils";
import { useTranslation } from "react-i18next";

const ContactInfoStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [kycData, setKycData] = useState(kycFields);
  const { countries, cities } = useSelector((state) => state.general);
  const [isLoading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState("");

  const handleNavigation = () => {
    navigate({
      pathname: employmentInfoStepRoute,
    });
  };

  const saveKycData = async () => {
    try {
      setLoading(true);
      await putKycData(orderId, kycData, StepEnums.CONTACT_INFO_STEP);
      handleNavigation();
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
    }
  };

  const handleNextActionClick = () => {
    saveKycData();
  };

  const fetchKycData = async () => {
    try {
      setLoading(true);
      const { orderId, orderKycId, mobileNumber, email } = await getCustomer();
      setOrderId(orderId);
      const data = await getKycData(orderId, orderKycId);
      setKycData(data);
      setKycData((prev) => ({
        ...prev,
        [KycKeys.EMAIL]: {
          ...prev[KycKeys.EMAIL],
          value: email,
        },
        [KycKeys.CONTACT_MOBILE_NUMBER]: {
          ...prev[KycKeys.CONTACT_MOBILE_NUMBER],
          value: mobileNumber,
        },
      }));
    } catch (exception) {
      console.error(exception);
    } finally {
      setLoading(false);
      scrollToTop();
    }
  };

  const isContinueDisabled = Object.keys(kycData)
    .filter(
      (key) =>
        kycData[key].step === StepEnums.CONTACT_INFO_STEP &&
        kycData[key].isVisible
    )
    .some(
      (key) =>
        (!kycData[key].isOptional &&
          !kycData[key].value &&
          kycData[key].value !== false) ||
        kycData[key].error
    );

  useEffect(() => {
    fetchKycData();
    return () => {
      setKycData(kycFields);
    };
  }, []);

  const renderFooter = () => (
    <PrimaryButton
      disabled={isContinueDisabled || isLoading}
      onClick={() => {
        handleNextActionClick();
      }}
      data-testid={"saveandcontinue"}
    >
      {t("saveAndContinueButton")}
    </PrimaryButton>
  );
  return (
    <MainLayout
      stepTitle={t("contactInfo")}
      footer={renderFooter}
      stepNumber={2}
    >
      {isLoading ? (
        <SkeletonLoader numberOfFields={8} />
      ) : (
        <>
          <Country
            data={{
              [KycKeys.COUNTRY]: kycData[KycKeys.COUNTRY],
            }}
            setData={setKycData}
            countries={countries}
            translator={t}
          />
          <City
            data={{
              [KycKeys.ADDRESS_CITY]: kycData[KycKeys.ADDRESS_CITY],
            }}
            setData={setKycData}
            cities={cities}
            translator={t}
          />
          <Area
            data={{
              [KycKeys.ADDRESS_AREA]: kycData[KycKeys.ADDRESS_AREA],
              [KycKeys.ADDRESS_CITY]: kycData[KycKeys.ADDRESS_CITY],
            }}
            setData={setKycData}
            cities={cities}
            translator={t}
          />
          <Block
            data={{
              [KycKeys.ADDRESS_BLOCK]: kycData[KycKeys.ADDRESS_BLOCK],
            }}
            setData={setKycData}
            translator={t}
          />
          <Street
            data={{
              [KycKeys.ADDRESS_STREET]: kycData[KycKeys.ADDRESS_STREET],
            }}
            setData={setKycData}
            translator={t}
          />
          <House
            data={{
              [KycKeys.ADDRESS_HOUSE]: kycData[KycKeys.ADDRESS_HOUSE],
            }}
            setData={setKycData}
            translator={t}
          />
          {/* <PhoneNumber
            data={{
              [KycKeys.CONTACT_MOBILE_NUMBER]:
                kycData[KycKeys.CONTACT_MOBILE_NUMBER],
              [KycKeys.COUNTRY_CODE]: kycData[KycKeys.COUNTRY_CODE],
            }}
            setData={setKycData}
          /> */}
          {/* <Email
            data={{
              [KycKeys.EMAIL]: kycData[KycKeys.EMAIL],
            }}
            setData={setKycData}
            translator={t}
          /> */}
        </>
      )}
    </MainLayout>
  );
};

export default ContactInfoStep;
