import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { otpSuccessRoute } from "./routes.const";
import { getToken } from "../utils/auth";

const NoAuthRoute = (props) => {
  const token = getToken();

  if (token) {
    return <Navigate to={otpSuccessRoute} />;
  }

  return <Outlet {...props} />;
};

export default NoAuthRoute;
