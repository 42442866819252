export const PutCustomerOrderKycUrl = (orderId) =>
  `/v1/web-onboarding/customer/order/${orderId}/kyc`;
export const GetCustomerOrderKycUrl = (orderId, orderKycId) =>
  `/v1/web-onboarding/customer/order/${orderId}/kyc/${orderKycId}`;
export const PostCustomerUrl = "/v1/web-onboarding/customer";
export const PostCustomerOrderUrl = `/v1/web-onboarding/customer/order`;
export const PutCustomerOrderUrl = (orderId) =>
  `/v1/web-onboarding/customer/order/${orderId}`;
export const GetCustomerUrl = `/v1/web-onboarding/customer`;
export const GetCustomerOrderUrl = (orderId) =>
  `/v1/web-onboarding/customer/order/${orderId}`;
export const GetCountriesAndCitiesUrl =
  "/v1/web-onboarding/customers/kyc/countries";
export const GetKycFieldsUrl = "/v1/web-onboarding/customers/kyc/fields";
export const PutCutomerOrderBrokerage = (orderId, orderBrokerageId) =>
  `/v1/web-onboarding/customer/order/${orderId}/brokerage/${orderBrokerageId}`;

export const PutEmailAndLanguage = `/v1/web-onboarding/customer`;

export const AuthorizeCustomer = "/v1/web-onboarding/auth/token ";
