import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import { convertBooleanToYesNo } from "../../../helpers";
import PropTypes from "prop-types";
import { generalEnums } from "../../../enums/general";

const PoliticalPositionRelationship = ({
  data,
  setKycData,
  kycFields,
  translator,
}) => {
  const { relationship } = kycFields;
  const fieldKey = KycKeys.POLITICAL_POSITION_RELATIONSHIP;
  const politicalPositionIsMeKey = KycKeys.POLITICAL_POSITION_IS_ME;

  const {
    [fieldKey]: { value, error },
    [politicalPositionIsMeKey]: { value: politicalPositionIsMeValue },
  } = data;

  const isVisible =
    convertBooleanToYesNo(politicalPositionIsMeValue) === generalEnums.NO;

  const items = Object.entries(relationship)?.map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value: value,
        error: fieldErrorObject.errorMessage,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);
  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"relationToFamilyMember"}
      inputOptions={items}
      label={translator("relationToFamilyMember")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

PoliticalPositionRelationship.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default PoliticalPositionRelationship;
