import React from "react";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";
import { internationalTypeOptions } from "../../../config";
import { useTranslation } from "react-i18next";

const InternationalBrokerageType = ({ data, setData, isDisabled }) => {
  const { t } = useTranslation("common");

  const typeOptions = internationalTypeOptions.map((type) => ({
    value: type.value,
    label: t(type.label),
  }));

  const validate = (value) => {
    setData(value);
  };

  const handleChange = (value) => {
    validate(value);
  };

  return (
    <CustomSelect
      name={t("type")}
      isDisabled={isDisabled}
      label={t("type")}
      onChange={handleChange}
      value={data}
      inputOptions={typeOptions}
    />
  );
};

InternationalBrokerageType.propTypes = {
  data: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InternationalBrokerageType;
