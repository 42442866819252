import React from "react";
import styles from "./product-card.module.scss";
import PropTypes from "prop-types";

const ProductCard = ({ product, productChosen, onClick }) => (
  <div
    className={
      productChosen === product.id
        ? styles.cardLayoutActive
        : styles.cardLayoutNotActive
    }
    onClick={() => {
      onClick(product.id);
    }}
    data-testid={product.id}
  >
    <div className={styles.mainContainer}>
      <img src={product.icon} alt={"icon"} className={styles.icon} />
      <div className={styles.innerContainer}>
        <span className={styles.title}>{product.title}</span>
        {product.descriptions.map((description, index) => (
          <div className={styles.descriptionContainer} key={index}>
            <div className={styles.bullet} />
            <span className={styles.description}>{description}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  productChosen: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ProductCard;
