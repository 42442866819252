import React, { useEffect } from "react";
import "./App.css";
import PageRouter from "./routes";
import { Provider } from "react-redux";
import webOnBoardingStore from "./store";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
  }, [i18n, i18n.language]);
  return (
    <Provider store={webOnBoardingStore}>
      <div className="App" data-testid="app">
        <PageRouter />
      </div>
    </Provider>
  );
}

export default App;
