import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import employerTypeEnums from "../../../enums/employer-type";
import PropTypes from "prop-types";

const BusinessSector = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.PRIVATE_SECTOR_INDUSTRY;
  const employerTypeKey = KycKeys.EMPLOYER_TYPE;
  const { sectors } = kycFields;
  const {
    [fieldKey]: { value, error },
    [employerTypeKey]: { value: employerTypeValue },
  } = data;

  const isVisible = employerTypeValue === employerTypeEnums.PRIVATE_SECTOR;

  const businessSectorsOptions = Object.keys(sectors)?.map((key) => ({
    label: sectors[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };
  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return;
  }

  return (
    <CustomSelect
      name={"businessSector"}
      inputOptions={businessSectorsOptions}
      label={translator("businessSectorLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

BusinessSector.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default BusinessSector;
