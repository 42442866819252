import React, { useEffect } from "react";
import InputField from "../../../widgets/inputs/primary-input";
import FieldsValidation from "../../../validation/fields-validation";
import KycKeys from "../../../enums/kyc-enum";
import { emailAndMobileValidations } from "../../../utils/validation/validation-messages";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const EmailAddressField = ({ data, setData }) => {
  const { t } = useTranslation("common");
  const fieldKey = KycKeys.EMAIL;
  const { emailAddress: emailAddressValue } = useSelector(
    (state) => state.general.userInfo
  );
  const {
    [fieldKey]: { value: emailAddress, error: emailError },
    [KycKeys.EMAIL_CONFIRM]: {
      value: confirmEmailAddress,
      error: confirmedEmailError,
    },
  } = data;

  const validateField = (value) => {
    const fieldErrorObject = FieldsValidation.validateEmail(
      value,
      confirmEmailAddress,
      confirmedEmailError
    );
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };

  useEffect(() => {
    if (confirmEmailAddress && emailAddress !== confirmEmailAddress) {
      setData((prev) => ({
        ...prev,
        [KycKeys.EMAIL_CONFIRM]: {
          value: confirmEmailAddress,
          error: emailAndMobileValidations.enterValidConfirmedEmail,
        },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [KycKeys.EMAIL_CONFIRM]: {
          value: confirmEmailAddress,
          error: "",
        },
      }));
    }
  }, [emailAddress]);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        value: emailAddressValue,
      },
    }));
  }, []);

  return (
    <InputField
      isDisabled={false}
      label={t("emailAddressLabel")}
      onChange={handleChange}
      value={emailAddress}
      errorMessage={emailError}
      name={"email"}
    />
  );
};
export default EmailAddressField;
