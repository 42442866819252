import React, { useRef, useCallback, useState } from "react";
import Webcam from "react-webcam";
import mobileCloseIcon from "../../assets/icons/white-close-icon.svg";
import successIcon from "../../assets/icons/success-icon.svg";
import refreshIcon from "../../assets/icons/refresh-icon.svg";
import styles from "./photo-capture.module.scss";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";

const PhotoCapture = ({ isOpened, setIsOpened, setCapturedImage }) => {
  const [previewImage, setPreviewImage] = useState("");
  const webcamRef = useRef(null);

  const captureImage = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPreviewImage(imageSrc);
  }, [webcamRef]);

  const videoConstraints = {
    facingMode: "user",
  };

  const handleCloseCamera = () => {
    setIsOpened(!isOpened);
    setCapturedImage("");
    setPreviewImage("");
  };

  const handleImageSubmit = async () => {
    try {
      const blob = await fetch(previewImage).then((res) => res.blob());
      setCapturedImage(blob);
    } catch (exception) {
      console.error(exception);
    }
  };

  const handleRetakePhoto = () => {
    setPreviewImage("");
  };

  if (!isOpened) {
    return null;
  }

  return createPortal(
    <div className="photoCaptureContainer" data-testid="webcam-container">
      <div
        className={styles.darkBackground}
        onClick={() => setIsOpened(false)}
        data-testid="photo-capture-modal-background"
      />
      <div className={styles.cameraModal}>
        <img
          data-testid="close-modal-button"
          src={mobileCloseIcon}
          className={styles.cameraCloseIcon}
          onClick={handleCloseCamera}
        />
        {!previewImage && (
          <>
            <Webcam
              data-testid="webcam"
              className={styles.camera}
              mirrored
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              videoConstraints={videoConstraints}
            />
            <div
              data-testid="capture-button"
              className={styles.cameraButton}
              onClick={(e) => captureImage(e)}
            >
              <div className={styles.cameraCircle} />
              <div className={styles.cameraRing} />
            </div>
          </>
        )}
        <div
          style={{
            display: previewImage ? "block" : "none",
            width: "100%",
            height: "100%",
          }}
        >
          <div className={styles.previewImage} data-testid="preview-image">
            <img src={previewImage} />
          </div>
          <div className={styles.previewActions}>
            <img
              src={refreshIcon}
              onClick={handleRetakePhoto}
              data-testid="retake-icon"
            />
            <img
              src={successIcon}
              onClick={handleImageSubmit}
              data-testid="success-icon"
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
};

PhotoCapture.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  setCapturedImage: PropTypes.func.isRequired,
};

export default PhotoCapture;
