import axiosInstance from "../../network/axios-instance";
import { PostOTPUrl } from "./phone-number-details-urls.const";

export const sendOTP = async (payload) => {
  try {
    const response = await axiosInstance.post(PostOTPUrl, payload);
    return response.data || {};
  } catch (exception) {
    throw exception;
  }
};
