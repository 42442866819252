export const generateContract = (orderId) =>
  `v1/web-onboarding/customer/order/${orderId}/document/generate`;

export const getContractSessionUrl = (orderContractSessionId) =>
  `v1/web-onboarding/customer/order/contract/session/${orderContractSessionId}`;

export const createContractSessionUrl = (orderId) =>
  `v1/web-onboarding/customer/order/${orderId}/contract/session`;

export const submissionToAdminUrl = (orderId) =>
  `v1/web-onboarding/customer/order/${orderId}/submission`;

export const contractGenerationStatus = (orderId, contractId) =>
  `v1/web-onboarding/customer/order/${orderId}/document/generate/${contractId}/status`;
