import React from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";
import { generalEnums } from "../../../enums/general";

const UsCitizen = ({ data, setKycData, translator, kycFields }) => {
  const fieldKey = KycKeys.US_CITIZEN;
  const taxPayerIdentificationKey = KycKeys.TAX_PAYER_IDENTIFICATION;
  const usAddressOnekey = KycKeys.US_ADDRESS_1;
  const usAddressTwokey = KycKeys.US_ADDRESS_2;
  const usAddressThreekey = KycKeys.US_ADDRESS_3;
  const paysTaxesInOtherCountryKey = KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY;
  const taxCountry2Key = KycKeys.TAX_COUNTRY_2;
  const taxCountry3Key = KycKeys.TAX_COUNTRY_3;
  const taxPayerId2Key = KycKeys.TAX_PAYER_ID_2;
  const taxPayerId3Key = KycKeys.TAX_PAYER_ID_3;

  const {
    [fieldKey]: { value, error },
  } = data;
  const { yes_no: yesOrNo } = kycFields;
  const usCitizenValue = convertBooleanToYesNo(value);
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: convertYesNoToBoolean(value),
      },
      [taxPayerIdentificationKey]: {
        ...prev[taxPayerIdentificationKey],
        error: "",
        value: "",
      },
      [usAddressOnekey]: {
        ...prev[usAddressOnekey],
        error: "",
        value: "",
      },
      [usAddressTwokey]: {
        ...prev[usAddressTwokey],
        error: "",
        value: "",
      },
      [usAddressThreekey]: {
        ...prev[usAddressThreekey],
        error: "",
        value: "",
      },
      [paysTaxesInOtherCountryKey]: {
        ...prev[paysTaxesInOtherCountryKey],
        error: "",
        value: value === generalEnums.YES ? generalEnums.YES : "",
      },
      [taxCountry2Key]: {
        ...prev[taxCountry2Key],
        error: "",
        value: "",
      },
      [taxCountry3Key]: {
        ...prev[taxCountry3Key],
        error: "",
        value: "",
      },
      [taxPayerId2Key]: {
        ...prev[taxPayerId2Key],
        error: "",
        value: "",
      },
      [taxPayerId3Key]: {
        ...prev[taxPayerId3Key],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  return (
    <CustomSelect
      name={"isUsCitizen"}
      inputOptions={options}
      label={translator("isUsCitizen")}
      onChange={handleChange}
      value={usCitizenValue}
      errorMessage={error}
    />
  );
};

UsCitizen.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default UsCitizen;
