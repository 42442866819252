import React, { useState } from "react";
import MainLayout from "../../layouts/main-layout";
import { useTranslation } from "react-i18next";
import TypeBox from "./components/type-box";
import styles from "./international-brokerage.module.scss";
import PrimaryButton from "../../widgets/buttons/primary-button";
import {
  getSavedInternationalType,
  setSaveInternationalType,
} from "../../utils";
import { civildIDetailsRoute } from "../../routes/routes.const";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/alert-component-not-fixed";
import infoIcon from "../../assets/icons/blue-info-icon.svg";
import { internationalBrokerageTypesEnums } from "../../enums/general";

const InternationalBrokerage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [selectedId, setSelectedId] = useState(getSavedInternationalType());
  const internationalTypes = [
    {
      id: internationalBrokerageTypesEnums.CASH,
      title: t("cashAccounts"),
      subtitle: t("cashAccountsDesctiption"),
      badgeTitle: t("preferred"),
    },
    {
      id: internationalBrokerageTypesEnums.MARGIN,
      title: t("marginAccounts"),
      subtitle: t("marginAccountsDesctiption"),
      badgeTitle: null,
    },
  ];
  const onTypeClick = (typeId) => {
    setSelectedId(typeId);
  };

  const renderFooter = () => (
    <PrimaryButton
      disabled={!selectedId}
      onClick={() => {
        setSaveInternationalType(selectedId);
        navigate(civildIDetailsRoute);
      }}
      data-testid="get started"
    >
      {t("getStarted")}
    </PrimaryButton>
  );

  return (
    <MainLayout
      title={t("internationalMarkets")}
      subTitle={t("internationalMarketsSubtitle")}
      footer={renderFooter}
    >
      <div className={styles.container}>
        {internationalTypes.map((type) => (
          <TypeBox
            selectedId={selectedId}
            title={type.title}
            subtitle={type.subtitle}
            badgeTitle={type.badgeTitle}
            id={type.id}
            handleclick={onTypeClick}
          />
        ))}
        {selectedId && (
          <Alert
            text={t("brokerageInfoMessage")}
            leftIcon={infoIcon}
            styling={{ backGroundColor: "#EEF4FD", textColor: "#7595F5" }}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default InternationalBrokerage;
