import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";
import PropTypes from "prop-types";

const PoliticalPositionIsMe = ({ data, setKycData, translator, kycFields }) => {
  const fieldKey = KycKeys.POLITICAL_POSITION_IS_ME;
  const politicalExistingFieldKey = KycKeys.POLITICAL_POSITION_EXISTING;
  const politicalPositionNameKey = KycKeys.POLITICAL_POSITION_NAME;
  const politicalPositionRelationKey = KycKeys.POLITICAL_POSITION_RELATIONSHIP;

  const {
    [fieldKey]: { value, error },
    [politicalExistingFieldKey]: { value: politicalExistingValue },
  } = data;
  const { yes_no: yesOrNo } = kycFields;
  const politicalPositionIsMeValue = convertBooleanToYesNo(value);
  const isVisible = politicalExistingValue;

  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: convertYesNoToBoolean(value),
      },
      [politicalPositionNameKey]: {
        ...prev[politicalPositionNameKey],
        error: "",
        value: "",
      },
      [politicalPositionRelationKey]: {
        ...prev[politicalPositionRelationKey],
        error: "",
        value: "",
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"isMePep"}
      inputOptions={options}
      label={translator("isMePep")}
      onChange={handleChange}
      value={politicalPositionIsMeValue}
      errorMessage={error}
    />
  );
};

PoliticalPositionIsMe.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};

export default PoliticalPositionIsMe;
