import React, { useState } from "react";
import MainLayout from "../../layouts/main-layout";
import PrimaryButton from "../../widgets/buttons/primary-button";
import CivilIdField from "./fields/civil-id-number";
import { alertMessagesMapping } from "../../config";
import SerialNumberField from "./fields/serial-number";
import "../../../src/theme/spacing.scss";
import { useNavigate } from "react-router-dom";
import {
  phoneNumberDetailsRoute,
  otpVerification,
} from "../../routes/routes.const";
import styles from "./civil-id-details.module.scss";
import civilIDDetailsData from "./config";
import KycKeys from "../../enums/kyc-enum";
import { getCustomerStatus } from "./civil-id-details.proxy";
import { sendOTP } from "../phone-number-details/phone-number-details.proxy";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import errorCloseIcon from "../../assets/icons/alert-close-icon.svg";
import errorInfoIcon from "../../assets/icons/alert-info-icon.svg";
import Alert from "../../components/alert-component-fixed";
import { setUserInfo } from "../../store/slices/general.slice";

const CivilIdDetails = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [civilIDFields, setCivilIdFields] = useState(civilIDDetailsData);
  const [exception, setException] = useState("");
  const { userInfo } = useSelector((state) => state.general);
  const {
    [KycKeys.CIVIL_ID_NUMBER]: { value: civilIdValue },
    [KycKeys.CIVIL_ID_SERIAL]: { value: serialNumberValue },
  } = civilIDFields;

  const isContinueDisabled = Object.keys(civilIDFields).some(
    (key) => !civilIDFields[key].value || civilIDFields[key].error || isLoading
  );

  const customerPayload = {
    civil_id_number: civilIdValue,
    civil_id_serial: serialNumberValue,
  };

  const customerSendOTPPayload = {
    civil_id_number: civilIdValue,
    civil_id_serial: serialNumberValue,
    phone_number: null,
  };

  const sendCustomerOTP = async () => {
    try {
      setIsLoading(true);
      await sendOTP(customerSendOTPPayload);
    } catch (exception) {
      throw exception;
    } finally {
      setIsLoading(false);
    }
  };

  const checkCustomerStatus = async () => {
    try {
      setIsLoading(true);
      const response = await getCustomerStatus(customerPayload);

      if (response.customerId) {
        await sendCustomerOTP();
        navigate(otpVerification);
      } else {
        navigate(phoneNumberDetailsRoute);
      }
    } catch (exception) {
      setException(exception);
      throw new Error(exception);
    } finally {
      setIsLoading(false);
    }
  };

  const onContinue = async () => {
    dispatch(
      setUserInfo({
        ...userInfo,
        civilIDNumber: civilIdValue,
        serialNumber: serialNumberValue,
      })
    );

    setException("");
    try {
      await checkCustomerStatus();
    } catch (exception) {
      console.error(exception);
    }
  };

  const renderFooter = () => (
    <PrimaryButton
      onClick={onContinue}
      disabled={isContinueDisabled}
      data-testid="continue"
    >
      {t("continue")}
    </PrimaryButton>
  );

  const getAlertMessage = () => {
    if (!exception || !exception.response || !exception.response.data) {
      return;
    }
    const { description } = exception.response.data;

    if (!description) {
      return;
    }

    const translationKey = alertMessagesMapping[description];

    return translationKey
      ? t(translationKey)
      : exception.response.data.description;
  };

  return (
    <>
      <MainLayout
        title={t("enterCivilIdMessage")}
        footer={renderFooter}
        withAlertMessage={exception}
      >
        <div className={styles.container}>
          <CivilIdField
            data={{
              [KycKeys.CIVIL_ID_NUMBER]: civilIDFields[KycKeys.CIVIL_ID_NUMBER],
            }}
            setData={setCivilIdFields}
            setException={setException}
          />
          <SerialNumberField
            data={{
              [KycKeys.CIVIL_ID_SERIAL]: civilIDFields[KycKeys.CIVIL_ID_SERIAL],
            }}
            setData={setCivilIdFields}
            setException={setException}
          />
        </div>
      </MainLayout>

      {exception && (
        <Alert
          text={getAlertMessage()}
          setText={setException}
          leftIcon={errorInfoIcon}
          rightIcon={errorCloseIcon}
          styling={{ backGroundColor: "#ffedf0", textColor: "#e94a66" }}
        />
      )}
    </>
  );
};

export default CivilIdDetails;
