import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productChosen: null,
  userInfo: {
    civilIDNumber: "",
    serialNumber: "",
    phoneNumber: "",
    emailAddress: "",
    confirmEmailAddress: "",
  },
  countries: [],
  countriesAr: [],
  countriesEn: [],
  cities: [],
  kycFields: {},
  kycFieldsAr: {},
  kycFieldsEn: {},
  hideNavigation: false,
  kycSubmitted: false,
  showSessionExpiredModal: false,
  isAllStepsDone: false,
};

const generalSlice = createSlice({
  name: "generalSlice",
  initialState,
  reducers: {
    resetUserInfoState: (state) => {
      state.userInfo = initialState.userInfo;
    },
    resetStateOnLogout: (state) => {
      state.userInfo = initialState.userInfo;
      state.productChosen = initialState.productChosen;
      state.hideNavigation = initialState.hideNavigation;
      state.kycSubmitted = initialState.kycSubmitted;
      state.isAllStepsDone = initialState.isAllStepsDone;
    },
    setProductChosen: (state, { payload }) => {
      state.productChosen = payload;
    },
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
    setCountries: (state, { payload }) => {
      state.countries = payload;
    },
    setCountriesAr: (state, { payload }) => {
      state.countriesAr = payload;
    },
    setCountriesEn: (state, { payload }) => {
      state.countriesEn = payload;
    },
    setCities: (state, { payload }) => {
      state.cities = payload;
    },
    setKycFields: (state, { payload }) => {
      state.kycFields = payload;
    },
    setKycFieldsAr: (state, { payload }) => {
      state.kycFieldsAr = payload;
    },
    setKycFieldsEn: (state, { payload }) => {
      state.kycFieldsEn = payload;
    },
    setHideNavigation: (state, { payload }) => {
      state.hideNavigation = payload;
    },
    setKycSubmitted: (state, { payload }) => {
      state.kycSubmitted = payload;
    },
    setShowSessionExpiredModal: (state, { payload }) => {
      state.showSessionExpiredModal = payload;
    },
    setIsAllStepsDone: (state, { payload }) => {
      state.isAllStepsDone = payload;
    },
  },
});

export const {
  setProductChosen,
  setUserInfo,
  setCountries,
  setCities,
  setKycFields,
  setHideNavigation,
  resetStateOnLogout,
  resetUserInfoState,
  setKycFieldsAr,
  setKycFieldsEn,
  setCountriesAr,
  setCountriesEn,
  setKycSubmitted,
  setShowSessionExpiredModal,
  setIsAllStepsDone,
} = generalSlice.actions;
const generalReducer = generalSlice.reducer;

export default generalReducer;
