import React from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import CustomSelect from "../../../widgets/inputs/custom-select";

const Country = ({ data, setData, countries, translator }) => {
  const fieldKey = KycKeys.COUNTRY;
  const {
    [fieldKey]: { value, error, isDisabled },
  } = data;
  const countriesOptions = countries?.map((country) => ({
    value: country["alpha-2"],
    label: country.name,
  }));

  const validate = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);

    setData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = (value) => {
    validate(value);
  };

  return (
    <CustomSelect
      name={translator("country")}
      isDisabled={isDisabled}
      label={translator("country")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
      inputOptions={countriesOptions}
    />
  );
};

Country.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  translator: PropTypes.func.isRequired,
};

export default Country;
