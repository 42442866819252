import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import employmentStatusEnums from "../../../enums/employment-status-enum";
import PropTypes from "prop-types";

const EmployerType = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.EMPLOYER_TYPE;
  const entityTypeKey = KycKeys.GOVERNMENT_ENTITY_TYPE;
  const businessTypeKey = KycKeys.EMPLOYMENT_SECTOR;
  const businessNameKey = KycKeys.EMPLOYMENT_COMPANY;
  const entityNameKey = KycKeys.EMPLOYMENT_ENTITY_NAME;
  const organizationNameKey = KycKeys.EMPLOYMENT_ORGANIZATION_NAME;
  const isSeniorExecutiveOrLeadershipPositionKey =
    KycKeys.AUTH_SIGN_PROCESS_FINANCIAL_TRANSACTIONS;
  const lastEmploymentStatusKey = KycKeys.LAST_EMPLOYMENT_STATUS;
  const employmentStatusKey = KycKeys.EMPLOYMENT_STATUS;
  const businessSectorKey = KycKeys.PRIVATE_SECTOR_INDUSTRY;
  const departmentKey = KycKeys.EMPLOYMENT_DEPARTMENT;
  const jobTitleKey = KycKeys.JOB_TITLE;
  const { employerTypes } = kycFields;
  const {
    [fieldKey]: { value, error },
    [employmentStatusKey]: { value: employmentStatusValue },
    [lastEmploymentStatusKey]: { value: lastEmploymentStatusValue },
  } = data;

  const employmentStatusValues = [
    employmentStatusEnums.EMPLOYED,
    employmentStatusEnums.SELF_EMPLOYED,
  ];
  const isVisible = [employmentStatusValue, lastEmploymentStatusValue].some(
    (value) => employmentStatusValues.includes(value)
  );

  const employerTypeOptions = Object.keys(employerTypes)?.map((key) => ({
    label: employerTypes[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
      [entityTypeKey]: {
        ...prev[entityTypeKey],
        error: "",
        value: "",
      },
      [businessTypeKey]: {
        ...prev[businessTypeKey],
        error: "",
        value: "",
      },
      [businessNameKey]: {
        ...prev[businessNameKey],
        error: "",
        value: "",
      },
      [entityNameKey]: {
        ...prev[entityNameKey],
        error: "",
        value: "",
      },
      [organizationNameKey]: {
        ...prev[organizationNameKey],
        error: "",
        value: "",
      },
      [isSeniorExecutiveOrLeadershipPositionKey]: {
        ...prev[isSeniorExecutiveOrLeadershipPositionKey],
        error: "",
        value: "",
      },
      [businessSectorKey]: {
        ...prev[businessSectorKey],
        error: "",
        value: "",
      },
      [departmentKey]: { ...prev[departmentKey], error: "", value: "" },
      [jobTitleKey]: { ...prev[jobTitleKey], error: "", value: "" },
    }));
  };

  const handleChange = (changedVal) => {
    if (value !== changedVal) {
      validateField(changedVal);
    }
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return;
  }

  return (
    <CustomSelect
      name={"employerType"}
      inputOptions={employerTypeOptions}
      label={translator("employerTypeLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

EmployerType.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default EmployerType;
