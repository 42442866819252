import axiosInstance from "../../network/axios-instance";
import {
  generateContract,
  getContractSessionUrl,
  createContractSessionUrl,
  submissionToAdminUrl,
  contractGenerationStatus,
} from "./contract-urls.const";

export const generateClientContract = async (orderId) => {
  try {
    const response = await axiosInstance.post(generateContract(orderId));
    return response.data || {};
  } catch (exception) {
    throw new Error("Generate URL failed!");
  }
};

export const createContractSession = async (orderId) => {
  try {
    const response = await axiosInstance.post(
      createContractSessionUrl(orderId)
    );
    return response.data || {};
  } catch (exception) {
    throw new Error("Create contract session failed!");
  }
};

export const getContractSession = async (orderContractSessionId) => {
  try {
    const response = await axiosInstance.get(
      getContractSessionUrl(orderContractSessionId)
    );
    return response.data || {};
  } catch (exception) {
    throw new Error("Fetch session data failed!");
  }
};

export const submitToAdmin = async (orderId) => {
  try {
    const response = await axiosInstance.post(submissionToAdminUrl(orderId));
    return response.data || {};
  } catch (exception) {
    throw new Error("Submission failed!");
  }
};

export const getContractGenerationStatus = async (orderId, contractId) => {
  try {
    const response = await axiosInstance.get(
      contractGenerationStatus(orderId, contractId)
    );
    return response.data || {};
  } catch (exception) {
    throw new Error("Fetch status data failed!");
  }
};
