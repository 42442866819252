import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import { maritalStatusEnums } from "../../../enums/general";

const MaritalStatus = ({
  data,
  setKycData,
  kycFieldOptions = {},
  translator,
}) => {
  const fieldKey = KycKeys.MARRIAGE_STATUS;
  const partnerNameKey = KycKeys.PARTNER_NAME;
  const relationshipStatusKey = KycKeys.RELATIONSHIP_STATUS;

  const {
    [fieldKey]: { error, isDisabled },
    [relationshipStatusKey]: { value: relationshipStatusValue },
  } = data;

  const { relationshipStatus = {} } = kycFieldOptions;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isValidated: true,
        error: fieldErrorObject.errorMessage,
        value: value != maritalStatusEnums.SINGLE,
      },
      [relationshipStatusKey]: {
        ...prev[relationshipStatusKey],
        error: "",
        value: value,
      },
      [partnerNameKey]: {
        ...prev[partnerNameKey],
        error: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    if (relationshipStatusValue === maritalStatusEnums.SINGLE) {
      setKycData((prev) => ({
        ...prev,
        [fieldKey]: {
          ...prev[fieldKey],
          value: false,
        },
      }));
    }
  }, [relationshipStatusValue]);

  const handleChange = (value) => {
    validateField(value);
  };

  const relationshipStatusOption = Object.keys(relationshipStatus)?.map(
    (key) => ({
      label: relationshipStatus[key],
      value: key,
    })
  );

  return (
    <CustomSelect
      name={"maritalStatus"}
      label={translator("maritalStatus")}
      onChange={handleChange}
      value={relationshipStatusValue}
      errorMessage={error}
      inputOptions={relationshipStatusOption}
      isDisabled={isDisabled}
    />
  );
};

MaritalStatus.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFieldOptions: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default MaritalStatus;
