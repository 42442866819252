import React from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";

const InvestmentKnowledgeLevel = ({
  data,
  setKycData,
  kycFields,
  translator,
}) => {
  const fieldKey = KycKeys.INVESTMENT_LEVEL;

  const { investmentKnowledgeLevels } = kycFields;
  const {
    [fieldKey]: { value, error },
  } = data;

  const investmentKnowledgeLevelsOptions = Object.keys(
    investmentKnowledgeLevels
  )?.map((key) => ({
    label: investmentKnowledgeLevels[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };

  return (
    <CustomSelect
      name={"investmentKnowledgeLevel"}
      label={translator("investmentKnowledgeLevelLabel")}
      onChange={handleChange}
      value={value}
      inputOptions={investmentKnowledgeLevelsOptions}
      errorMessage={error}
    />
  );
};

InvestmentKnowledgeLevel.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default InvestmentKnowledgeLevel;
