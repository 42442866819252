import React, { useState } from "react";
import styles from "./info-box.module.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const InfoBox = ({ title, subTitle }) => {
  const { t } = useTranslation("common");
  const [isViewMore, setIsViewMore] = useState(false);
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <div className={styles.subTitleContainer}>
        <div
          className={styles.subTitle}
          style={{
            WebkitLineClamp: isViewMore ? 50 : 2,
          }}
        >
          {subTitle}
        </div>

        <span
          className={styles.viewMore}
          onClick={() => {
            setIsViewMore(!isViewMore);
          }}
        >
          {subTitle.length > 119 ? (
            isViewMore && subTitle.length > 200 ? (
              t("viewLess")
            ) : (
              t("viewMore")
            )
          ) : (
            <></>
          )}
        </span>
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default InfoBox;
