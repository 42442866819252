import React, { useEffect } from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";
import { generalEnums } from "../../../enums/general";
import { convertBooleanToYesNo } from "../../../helpers";

const BusinessType = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.PRIVATE_BUSINESS_SECTOR;
  const sameEmploymentDetailsKey =
    KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS;

  const { businessTypes } = kycFields;
  const {
    [fieldKey]: { value, error },
    [sameEmploymentDetailsKey]: { value: sameBusinessDetailsVal },
  } = data;

  const isVisible =
    convertBooleanToYesNo(sameBusinessDetailsVal) === generalEnums.NO;

  const businessTypesOptions = Object.keys(businessTypes)?.map((key) => ({
    label: businessTypes[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };
  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"businessType"}
      label={translator("typeOfBusinessLabel")}
      onChange={handleChange}
      value={value}
      inputOptions={businessTypesOptions}
      errorMessage={error}
    />
  );
};

BusinessType.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default BusinessType;
