import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import PropTypes from "prop-types";
import InputField from "../../../widgets/inputs/primary-input";

const TaxPayerIdThree = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.TAX_PAYER_ID_3;
  const paysTaxesInOtherCountryKey = KycKeys.PAYS_TAXES_IN_OTHER_COUNTRY;
  const taxCountry3Key = KycKeys.TAX_COUNTRY_3;

  const {
    [fieldKey]: { value, error, isDisabled },
    [paysTaxesInOtherCountryKey]: { value: paysTaxesInOtherCountryValue },
    [taxCountry3Key]: { value: taxCountry3Value },
  } = data;

  const isVisible = paysTaxesInOtherCountryValue && taxCountry3Value;

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateTaxPayerId(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value: value,
      },
    }));
  };

  const handleChange = ({ target: { value } }) => {
    validateField(value);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name={"taxIdThree"}
      isDisabled={isDisabled}
      label={translator("taxIdThree")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

TaxPayerIdThree.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};

export default TaxPayerIdThree;
