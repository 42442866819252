import React from "react";
import styles from "./primary-button.module.scss";

const PrimaryButton = (props) => (
  <button {...props} className={styles.primaryButton}>
    {props.children}
  </button>
);

export default PrimaryButton;
