import React, { useEffect } from "react";
import KYCValidator from "../../../helpers/validation";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import employmentStatusEnums from "../../../enums/employment-status-enum";
import PropTypes from "prop-types";

const LastEmploymentStatus = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.LAST_EMPLOYMENT_STATUS;
  const employmentStatusKey = KycKeys.EMPLOYMENT_STATUS;
  const { lastEmploymentStatuses } = kycFields;
  const {
    [fieldKey]: { value, error },
    [employmentStatusKey]: { value: employmentStatusValue },
  } = data;

  const isVisible = employmentStatusValue === employmentStatusEnums.RETIRED;

  const lastEmploymentStatusOptions = Object.keys(lastEmploymentStatuses)?.map(
    (key) => ({
      label: lastEmploymentStatuses[key],
      value: key,
    })
  );

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: fieldErrorObject.errorMessage,
        value,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };
  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return;
  }

  return (
    <CustomSelect
      name={"lastEmpStatus"}
      inputOptions={lastEmploymentStatusOptions}
      label={translator("lastEmploymentStatusLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};

LastEmploymentStatus.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};

export default LastEmploymentStatus;
