import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { landingRoute } from "./routes.const";
import { getToken } from "../utils/auth";

const ProtectedRoute = (props) => {
  const token = getToken();

  if (!token) {
    return <Navigate to={landingRoute} />;
  }

  return <Outlet {...props} />;
};

export default ProtectedRoute;
