import React from "react";
import styles from "./step.module.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Step = ({ step, lastStep }) => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.container} id={`${step.title}`}>
      <div className={styles.innerContainer1}>
        <img
          data-testid={`test-${step.title}`}
          src={step.isDone ? step.doneIcon : step.icon}
          width={38}
          alt={"icon"}
        />
        {!lastStep && <div className={styles.verticalLine} />}
      </div>

      <div className={styles.innerContainer2}>
        <span className={styles.title}>{t(`${step.title}`)}</span>
        <span className={styles.subTitle}>{t(`${step.subTitle}`)}</span>
      </div>
    </div>
  );
};

export default Step;
Step.propTypes = {
  step: PropTypes.object.isRequired,
  lastStep: PropTypes.bool.isRequired,
};
