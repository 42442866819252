import React, { useEffect, useRef, useState } from "react";
import { IMaskInput } from "react-imask";
import moment from "moment";
import IMask from "imask";
import styles from "./date-picker.module.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DatePicker = ({
  value,
  error,
  isDisabled = false,
  label,
  onChange,
  name,
}) => {
  const ref = useRef(null);
  const inputRef = useRef(null);
  const { t } = useTranslation("common");

  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const dateConfig = {
    delimiterFormat: "DD/MM/YYYY",
    dashedFormat: "YYYY-MM-DD",
  };

  const blocks = {
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      placeholderChar: "d",
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      placeholderChar: "m",
    },
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1000,
      to: 9999,
      placeholderChar: "y",
    },
  };

  const handleFocus = () => {
    setIsFocused(true);
    setIsActive(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (!value || value === "dd/mm/yyyy") {
      setIsActive(false);
    }
  };

  const handleLabelClick = () => {
    if (!isActive) {
      setIsActive(true);
      setIsFocused(true);
      inputRef.current.focus();
    }
  };

  const handleInput = (e) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    if (!value && !isFocused) {
      setIsActive(false);
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      setIsActive(true);
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.datePickerContainer} ${error ? styles.error : ""}`}
      >
        <label
          className={`${styles.floatingLabel} ${
            isActive ? styles.active : ""
          } ${isFocused && isActive ? styles.focused : ""} ${
            error ? styles.error : ""
          }`}
          onClick={handleLabelClick}
        >
          {label}
        </label>
        <IMaskInput
          data
          className={styles.datePickerField}
          mask={Date}
          pattern={dateConfig.delimiterFormat}
          blocks={blocks}
          lazy
          ref={ref}
          inputRef={inputRef}
          format={(date) =>
            moment(date, dateConfig.delimiterFormat).format(
              dateConfig.delimiterFormat
            )
          }
          parse={(str) =>
            moment(str, dateConfig.delimiterFormat).format(
              dateConfig.delimiterFormat
            )
          }
          value={value}
          disabled={isDisabled}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInput={handleInput}
          placeholder="dd/mm/yyyy"
          autoComplete="off"
        />
      </div>
      {error && <span className={styles.errorMessage}>{t(`${error}`)}</span>}
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default DatePicker;
