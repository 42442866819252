import React from "react";
import styles from "./tooltip.module.scss";
import PropTypes from "prop-types";

const TooltipMessage = ({ message, icon }) => (
  <div className={styles.tooltipContainer} data-testid="tooltip-container">
    <img src={icon} alt="tooltip-icon" />
    <span className={styles.tooltipText}>{message}</span>
  </div>
);

TooltipMessage.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default TooltipMessage;
