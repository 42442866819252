import { configureStore } from "@reduxjs/toolkit";
import generalReducer from "./slices/general.slice";

const webOnBoardingStore = configureStore({
  reducer: {
    general: generalReducer,
  },
});

export default webOnBoardingStore;
