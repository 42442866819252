import React, { useState } from "react";
import styles from "./alert.module.scss";
import PropTypes from "prop-types";

const Alert = ({ text, setText, leftIcon, rightIcon, styling }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
    setText("");
  };

  const { backGroundColor, textColor } = styling;

  if (isClosed) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: backGroundColor }}
    >
      {leftIcon && <img src={leftIcon} alt="Left Icon" />}
      <span className={styles.alertMessage} style={{ color: textColor }}>
        {text}
      </span>
      {rightIcon && (
        <img src={rightIcon} alt="Close Icon" onClick={handleClose} />
      )}
    </div>
  );
};
Alert.propTypes = {
  text: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  setText: PropTypes.func,
  styling: PropTypes.object,
};
export default Alert;
