const accountPurposeEnums = {
  OTHER: "other",
};

const incomeSourceEnums = {
  OTHER: "other",
  REAL_ESTATE: "real_estate",
  INVESTMENT: "investments",
  PRIVATE_BUSINESS: "private_business",
  PENSION_SOCIAL_SECURITY: "pension_social_security",
  JOB: "job",
};

const wealthSourceEnums = {
  JOB_INDEMNITY: "job_indemnity",
  INVESTMENT: "investments",
  INHERITANCE: "inheritance",
  SAVING: "saving",
  SALE_OF_PROPERTY: "sale_of_property",
  OTHER: "other",
};

const clientClassificationStatusEnums = {
  RETAIL: "retail",
  QUALIFIED_PROFESSIONAL: "qualified",
};

const bankENums = {
  NATIONAL_BANK_KUWAIT: "national_bank_kuwait",
  NATIONAL_BANK_KUWAIT_INTERNATIONAL: "national_bank_kuwait_international",
};
export {
  accountPurposeEnums,
  incomeSourceEnums,
  wealthSourceEnums,
  clientClassificationStatusEnums,
  bankENums,
};
