import React from "react";
import styles from "./logout-box.module.scss";
import logoutIcon from "../../assets/icons/logout-icon.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LogoutBox = ({ onClick }) => {
  const { t } = useTranslation("common");
  return (
    <div className={styles.container}>
      <div className={styles.dropdownButton} onClick={onClick}>
        <div className={styles.innerContent} data-testid={"logout"}>
          <p className={styles.dropdownValue}>{t("logout")}</p>
          <img
            className={styles.arrowIcon}
            src={logoutIcon}
            alt="Logout Icon"
          />
        </div>
      </div>
    </div>
  );
};

LogoutBox.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default LogoutBox;
