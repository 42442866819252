export const generalEnums = { YES: "yes", NO: "no" };
export const countriesEnums = { KUWAIT: "KW", UNITEDSTATES: "US" };
export const creatingOrderServicesEnums = {
  KYC_ONBOARDING: "kyc_onboarding",
  BROKERAGE: "brokerage",
};
export const customerStatusEnums = {
  ADDING_SERVICE_ENABLED: "adding_service_enabled",
  KYC_AND_CID_EXPIRED: "kyc_and_cid_expired",
  KYC_EXPIRED: "kyc_expired",
  EARLY_RENEWAL_PENDING_KYC_APPROVAL: "early_renewal_pending_kyc_approval",
  EARLY_RENEWAL_RETURNED_KYC_AND_DOCUMENTS:
    "early_renewal_returned_kyc_and_documents",
  EARLY_RENEWAL_RETURNED_SUPPORTING_DOCUMENTS:
    "early_renewal_returned_supporting_documents",
};

export const activeCustomerStatusEnums = [
  customerStatusEnums.ADDING_SERVICE_ENABLED,
  customerStatusEnums.EARLY_RENEWAL_PENDING_KYC_APPROVAL,
  customerStatusEnums.EARLY_RENEWAL_RETURNED_KYC_AND_DOCUMENTS,
  customerStatusEnums.EARLY_RENEWAL_RETURNED_SUPPORTING_DOCUMENTS,
];

export const maritalStatusEnums = {
  SINGLE: "single",
};
export const entityEnums = {
  NBK_KUWAIT: "nbk_kuwait",
};

export const brokerageEums = {
  REGIONAL_BROKERAGE_RBU: "regional_brokerage_rbu",
  INTERNATIONAL_BROKERAGE: "international_brokerage",
};

export const internationalBrokerageTypesEnums = {
  MARGIN: "margin",
  CASH: "cash",
};
export const countryCode = "+965";
