import React, { useEffect } from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";
import { generalEnums } from "../../../enums/general";
import { convertBooleanToYesNo } from "../../../helpers";

const BusinessSector = ({ data, setKycData, kycFields, translator }) => {
  const fieldKey = KycKeys.PRIVATE_BUSINESS_INDUSTRY;
  const sameEmploymentDetailsKey =
    KycKeys.PRIVATE_BUSINESS_SAME_EMPLOYMENT_DETAILS;

  const { businessSectors } = kycFields;
  const {
    [fieldKey]: { value, error },
    [sameEmploymentDetailsKey]: { value: sameBusinessDetailsVal },
  } = data;

  const isVisible =
    convertBooleanToYesNo(sameBusinessDetailsVal) === generalEnums.NO;

  const businessSectorsOptions = Object.keys(businessSectors)?.map((key) => ({
    label: businessSectors[key],
    value: key,
  }));

  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };

  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CustomSelect
      name={"businessSector"}
      label={translator("businessSectorLabel")}
      onChange={handleChange}
      value={value}
      inputOptions={businessSectorsOptions}
      errorMessage={error}
    />
  );
};

BusinessSector.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
  translator: PropTypes.func.isRequired,
};
export default BusinessSector;
