import axios from "axios";
import axiosInstance from "../../network/axios-instance";
import {
  PostPresignedUrl,
  UploadDocumentUrl,
  GetPresignedUrl,
} from "./identity-verification-urls.const";

export const postPresignedUrl = async (contentType) => {
  try {
    const response = await axiosInstance.post(PostPresignedUrl, {
      content_type: contentType,
    });
    return response.data || {};
  } catch (exception) {
    throw new Error("Generating presigned url failed!");
  }
};

export const uploadToS3Buckets = async (url, file, type) => {
  try {
    await axios.put(url, file, {
      headers: {
        "Content-Type": type,
      },
    });
  } catch (exception) {
    throw new Error("Upload to S3 Bucket failed!");
  }
};

export const postDocument = async (
  orderId,
  documentPath,
  documentType,
  contentType
) => {
  try {
    const response = await axiosInstance.post(UploadDocumentUrl(orderId), {
      document_path: documentPath,
      document_type: documentType,
      content_type: contentType,
    });
    return response.data || {};
  } catch (exception) {
    throw new Error("Creating document failed!");
  }
};

export const getPresignedUrl = async (documentId, contentType) => {
  try {
    const response = await axiosInstance.get(GetPresignedUrl(documentId), {
      content_type: contentType,
    });
    return response.data || {};
  } catch (exception) {
    throw new Error("Fetching presigned url failed!");
  }
};
