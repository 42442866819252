import React, { useState } from "react";
import Navbar from "../navbar";
import ResponsiveNavbar from "../responsive-navbar";
import useWindowWidth from "../../custom-hooks/useWindonWidth";
import Drawer from "../drawer";
import { useSelector } from "react-redux";
import SessionExpired from "../../modals/session-expired";

const Navigator = () => {
  const windowWidth = useWindowWidth();
  const [isOpen, setIsOpen] = useState(false);
  const isSessionExpired = useSelector(
    (state) => state.general.showSessionExpiredModal
  );
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
      {windowWidth > 700 ? (
        <Navbar />
      ) : (
        <ResponsiveNavbar openModal={openModal} />
      )}
      <Drawer isOpen={isOpen} closeModal={closeModal} />
      {isSessionExpired ? <SessionExpired /> : <></>}
    </div>
  );
};

export default Navigator;
