import React, { useEffect } from "react";
import InputField from "../../../widgets/inputs/primary-input";
import KYCValidator from "../../../helpers/validation";
import KycKeys from "../../../enums/kyc-enum";
import { wealthSourceEnums } from "../../../enums/income-and-wealth";
import PropTypes from "prop-types";

const WealthDetailsInvestments = ({ data, setKycData, translator }) => {
  const fieldKey = KycKeys.WEALTH_DETAILS_INVESTMENTS;
  const wealthSourceKey = KycKeys.WEALTH_SOURCE;

  const {
    [fieldKey]: { value, error },
    [wealthSourceKey]: { value: wealthSourceValue },
  } = data;

  const isVisible = wealthSourceValue.some(
    (item) => item === wealthSourceEnums.INVESTMENT
  );
  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
        error: errorMessageReceived,
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e.target.value);
  };
  useEffect(() => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        isVisible: isVisible,
      },
    }));
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <InputField
      name={"wealthDetailsInvestments"}
      isDisabled={false}
      label={translator("wealthDetailsInvestmentsLabel")}
      onChange={handleChange}
      value={value}
      errorMessage={error}
    />
  );
};
WealthDetailsInvestments.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
};
export default WealthDetailsInvestments;
