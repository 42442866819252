import React from "react";
import PropTypes from "prop-types";
import styles from "./contract-sign.module.scss";

const Contract = ({ srcURL }) => (
  <iframe
    src={srcURL}
    className={styles.contractIframe}
    data-testid="contract-iframe"
  />
);

Contract.propTypes = {
  srcURL: PropTypes.string.isRequired,
};

export default Contract;
