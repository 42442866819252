import React from "react";
import MainLayout from "../../layouts/main-layout";
import styles from "./landing-page.module.scss";
import { useNavigate } from "react-router-dom";
import {
  civildIDetailsRoute,
  journeyTimeLineRoute,
  productsListing,
} from "../../routes/routes.const";
import rightArrow from "../../assets/icons/righ-arrow.svg";
import PrimaryButton from "../../widgets/buttons/primary-button";
import { getToken } from "../../utils/auth";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const renderFooter = () => (
    <PrimaryButton
      onClick={() => {
        navigate(productsListing);
      }}
      data-testid="get started"
    >
      {t("getStartedUpper")}
    </PrimaryButton>
  );

  return (
    <MainLayout
      title={t("landingTitle")}
      subTitle={t("landingSubtitle")}
      footer={renderFooter}
    >
      <div className={styles.mainContainer}>
        <div
          data-testid="continue"
          className={styles.bottomContainer}
          onClick={() => {
            const token = getToken();
            if (!token) {
              navigate(civildIDetailsRoute);
              return;
            }
            navigate(journeyTimeLineRoute);
          }}
        >
          <div className={styles.bottomInnerContainer}>
            <span
              data-testid="continue onboarding"
              className={styles.mainTitle}
            >
              {t("resumeOnboarding")}
            </span>
            <span className={styles.subTitle}>{t("resumeDescription")}</span>
          </div>

          <img
            className={styles.iconStyle}
            src={rightArrow}
            alt={"right arrow"}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default LandingPage;
