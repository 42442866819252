import React from "react";
import KycKeys from "../../../enums/kyc-enum";
import CustomMultiSelect from "../../../widgets/inputs/custom-multi-select";
import PropTypes from "prop-types";

const OtherNationalities = ({
  data,
  setKycData,
  countries = [],
  translator,
}) => {
  const fieldKey = KycKeys.OTHER_NATIONALITIES;
  const nationalityKey = KycKeys.NATIONALITY;

  const {
    [fieldKey]: { value },
    [nationalityKey]: { value: nationalityValue },
  } = data;

  const countriesList = countries.filter(
    (country) => country["alpha-2"] !== nationalityValue
  );

  const validateField = (value) => {
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        value,
      },
    }));
  };

  const handleChange = (value) => {
    validateField(value);
  };

  const countriesOptions = Object.keys(countriesList)?.map((key) => ({
    label: countriesList[key].name,
    value: countriesList[key]["alpha-2"],
  }));

  return (
    <CustomMultiSelect
      name={"otherNationality"}
      label={translator("otherNationalitiesLabel")}
      onChange={handleChange}
      defaultValues={value}
      inputOptions={countriesOptions}
    />
  );
};

OtherNationalities.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  translator: PropTypes.func.isRequired,
};

export default OtherNationalities;
