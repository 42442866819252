import React from "react";
import CustomSelect from "../../../widgets/inputs/custom-select";
import KycKeys from "../../../enums/kyc-enum";
import KYCValidator from "../../../helpers/validation";
import PropTypes from "prop-types";
import { convertBooleanToYesNo, convertYesNoToBoolean } from "../../../helpers";

const InvestmentAdvisor = ({ data, setKycData, translator, kycFields }) => {
  const fieldKey = KycKeys.INVESTMENT_ADVISOR;

  const {
    [fieldKey]: { value, error },
  } = data;
  const investmentAdvisorValue = convertBooleanToYesNo(value);
  const { yesOrNo } = kycFields;
  const options = Object.keys(yesOrNo)?.map((key) => ({
    label: yesOrNo[key],
    value: key,
  }));
  const validateField = (value) => {
    const fieldErrorObject = KYCValidator.validateField(value);
    const errorMessageReceived = fieldErrorObject.errorMessage;
    setKycData((prev) => ({
      ...prev,
      [fieldKey]: {
        ...prev[fieldKey],
        error: errorMessageReceived,
        value: convertYesNoToBoolean(value),
      },
    }));
  };

  const handleChange = (e) => {
    validateField(e);
  };

  return (
    <CustomSelect
      name={"investmentAdvisor"}
      label={translator("clientHaveInvestmentAdvisorLabel")}
      onChange={handleChange}
      value={investmentAdvisorValue}
      inputOptions={options}
      errorMessage={error}
    />
  );
};

InvestmentAdvisor.propTypes = {
  data: PropTypes.object.isRequired,
  setKycData: PropTypes.func.isRequired,
  translator: PropTypes.func.isRequired,
  kycFields: PropTypes.object.isRequired,
};
export default InvestmentAdvisor;
