import doneIcon from "../../assets/icons/done-rounded-icon.svg";
import infoIcon from "../../assets/icons/info-icon.svg";

export const FRONT_CIVIL_ID = "civil_id_copy_front";
export const BACK_CIVIL_ID = "civil_id_copy_back";
export const CIVIL_ID_SELFIE = "civil_id_copy_selfie";

export const maxUploadedFileSize = 10485760; // 10 MB

const civilIDVerifcationLocale = {
  firstStepCivilIdVerificationNumber: "firstStepCivilIdVerificationNumber",
  secondStepCivilIdVerificationNumber: "secondStepCivilIdVerificationNumber",
  thirdStepCivilIdVerificationNumber: "thirdStepCivilIdVerificationNumber",
  uploadFrontCivilIdTitle: "uploadFrontCivilIdTitle",
  uploadBackCivilIdTitle: "uploadBackCivilIdTitle",
  uploadSelfieTitle: "uploadSelfieTitle",
  uploadFrontCivilIdSubTitle: "uploadFrontCivilIdSubTitle",
  uploadBackCivilIdSubTitle: "uploadBackCivilIdSubTitle",
  uploadSelfieSubTitle: "uploadSelfieSubTitle",
};

export const identityVerificationConfig = [
  {
    id: FRONT_CIVIL_ID,
    order: civilIDVerifcationLocale.firstStepCivilIdVerificationNumber,
    title: civilIDVerifcationLocale.uploadFrontCivilIdTitle,
    subTitle: civilIDVerifcationLocale.uploadFrontCivilIdSubTitle,
    stepDoneIcon: doneIcon,
    tooltipIcon: "",
    isDone: false,
    isDisabled: false,
    fileFormats: ["image/png", "image/jpg", "image/jpeg"],
    acceptedFileTypes: "image/png, image/jpg, image/jpeg",
    error: "",
  },
  {
    id: BACK_CIVIL_ID,
    order: civilIDVerifcationLocale.secondStepCivilIdVerificationNumber,
    title: civilIDVerifcationLocale.uploadBackCivilIdTitle,
    subTitle: civilIDVerifcationLocale.uploadBackCivilIdSubTitle,
    stepDoneIcon: doneIcon,
    tooltipIcon: "",
    isDone: false,
    isDisabled: true,
    fileFormats: ["image/png", "image/jpg", "image/jpeg"],
    acceptedFileTypes: "image/png, image/jpg, image/jpeg",
    error: "",
  },
  {
    id: CIVIL_ID_SELFIE,
    order: civilIDVerifcationLocale.thirdStepCivilIdVerificationNumber,
    title: civilIDVerifcationLocale.uploadSelfieTitle,
    subTitle: civilIDVerifcationLocale.uploadSelfieSubTitle,
    stepDoneIcon: doneIcon,
    tooltipIcon: infoIcon,
    isDone: false,
    isDisabled: true,
    capture: "user",
    fileFormats: ["image/jpeg", "image/jpg"],
    acceptedFileTypes: "image/jpeg, image/jpg",
    error: "",
  },
];
