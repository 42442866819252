import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InfoModal from "../../../../modals/upload-image-info.js/info-modal";
import MainLayout from "../../../../layouts/main-layout";
import civilIdSelfie from "../../../../assets/icons/civil-id-selfie.svg";
import closeIcon from "../../../../assets/icons/alert-close-icon.svg";
import AlertPhotoCapture from "../alert-photo-capture";
import { setHideNavigation } from "../../../../store/slices/general.slice";
import styles from "./verification-steps.module.scss";
import {
  postPresignedUrl,
  uploadToS3Buckets,
  postDocument,
} from "../../identity-verification-services.proxy";
import CircularLoader from "../../../../widgets/circular-loader";
import PropTypes from "prop-types";
import {
  BACK_CIVIL_ID,
  CIVIL_ID_SELFIE,
  FRONT_CIVIL_ID,
  maxUploadedFileSize,
} from "../../config";
import { useTranslation } from "react-i18next";
import PhotoCapture from "../../../../modals/photo-capture";
import {
  getNextFileId,
  validateUploadedFile,
  getAcceptedFileTypesById,
} from "../../helper";

const VerificationSteps = ({
  data,
  setVerificationData,
  orderId,
  renderFooter,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [capturedImage, setCapturedImage] = useState("");
  const [isPermitted, setIsPermitted] = useState(true);
  const [isCameraOpened, setIsCameraOpened] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleIconClick = (e) => {
    e.stopPropagation();
    dispatch(setHideNavigation(true));
    setOverlayVisible(true);
  };

  const handleCloseClick = () => {
    dispatch(setHideNavigation(false));
    setOverlayVisible(false);
  };

  const updateVerificationData = (fileIdToUpdate, propertiesToUpdate) => {
    setVerificationData((prev) =>
      prev.map((item) =>
        item.id === fileIdToUpdate ? { ...item, ...propertiesToUpdate } : item
      )
    );
  };

  const createDocument = async (file, fileType, fileId) => {
    try {
      setIsUploadLoading(true);
      const { path, url } = await postPresignedUrl(fileType);
      await uploadToS3Buckets(url, file, fileType);
      await postDocument(orderId, path, fileId, fileType);

      updateVerificationData(fileId, { isDone: true });

      if (fileId === FRONT_CIVIL_ID) {
        updateVerificationData(BACK_CIVIL_ID, { isDisabled: false });
      }

      if (fileId === BACK_CIVIL_ID) {
        updateVerificationData(CIVIL_ID_SELFIE, { isDisabled: false });
      }
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsUploadLoading(false);
      setIsCameraOpened(false);
      setCapturedImage("");
    }
  };

  const handleOpenCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setIsPermitted(true);
      setIsCameraOpened(true);
      stream.getTracks().forEach((track) => track.stop());
    } catch (exception) {
      console.error("Error accessing camera:", exception);
      setIsPermitted(false);
      setShowAlert(true);
    }
  };

  const validateFile = (file, allowedFormats) =>
    validateUploadedFile(file, maxUploadedFileSize, allowedFormats);

  const handleInvalidFile = (fileId, error) => {
    updateVerificationData(fileId, { error, isDone: false });
    const nextFileId = getNextFileId(fileId);
    updateVerificationData(nextFileId, { error: "" });
  };

  const handleValidFile = (file, fileId) => {
    updateVerificationData(fileId, { error: "" });
    createDocument(file, file.type, fileId);
  };

  const handleFileSelection = (e) => {
    const file = e.target.files[0];
    const fileId = e.target.name;

    if (!file) {
      return;
    }

    const allowedFormats = getAcceptedFileTypesById(data, fileId);
    const { isValid, error } = validateFile(file, allowedFormats);

    if (!isValid) {
      handleInvalidFile(fileId, error);
      return;
    }

    handleValidFile(file, fileId);
  };

  const renderVerificationSteps = () =>
    data.map((step, index) => (
      <div key={step.id} data-testid={step.id}>
        <div
          data-testid={`capture-${step.id}`}
          key={index}
          className={styles.stepContainer}
          style={{
            cursor: step.isDisabled ? "default" : "pointer",
            backgroundColor: step.isDisabled ? "rgba(244, 244, 244, 0.9)" : "",
          }}
          onClick={() => {
            if (step.capture && step.capture === "user" && !step.isDisabled) {
              handleOpenCamera();
            }
          }}
        >
          {!step.isDisabled && step.capture !== "user" && (
            <input
              data-testid={step.id}
              id={step.id}
              name={step.id}
              className={styles.fileUploadInput}
              type="file"
              accept={step.acceptedFileTypes}
              onChange={(e) => handleFileSelection(e)}
              capture={step.capture || undefined}
            />
          )}
          <div className={styles.stepContent}>
            {step.isDone ? (
              <img src={step.stepDoneIcon} className={styles.doneIcon} />
            ) : (
              <div
                className={`${styles.stepOrder} ${step.isDisabled ? styles.disabled : ""}`}
              >
                0{t(`${step.order}`)}
              </div>
            )}
            <div>
              <h3
                className={`${styles.stepTitle} ${step.isDisabled ? styles.disabled : ""}`}
              >
                {t(`${step.title}`)}
              </h3>
              <p
                className={`${styles.stepSubTitle} ${step.isDisabled ? styles.disabled : ""}`}
              >
                {t(`${step.subTitle}`)}
              </p>
            </div>
          </div>
          {step.tooltipIcon && (
            <div
              className={`${styles.tooltipContainer} ${step.isDisabled ? styles.disabled : ""}`}
            >
              <img
                src={step.tooltipIcon}
                onClick={(e) => {
                  if (step.isDisabled) {
                    return;
                  }
                  handleIconClick(e);
                }}
              />
            </div>
          )}
        </div>
        {step.error && (
          <span className={styles.errorMessage}>{t(step.error)}</span>
        )}
      </div>
    ));

  useEffect(() => {
    if (capturedImage) {
      createDocument(capturedImage, "image/jpeg", CIVIL_ID_SELFIE);
    }
  }, [capturedImage]);

  const checkCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setIsPermitted(true);
      setShowAlert(false);
      stream.getTracks().forEach((track) => track.stop());
    } catch (exception) {
      setIsPermitted(false);
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (showAlert) {
      const interval = setInterval(() => {
        checkCameraAccess();
      }, 500);

      return () => clearInterval(interval);
    }
  }, [showAlert]);

  return (
    <>
      <MainLayout title={t("civilIdVerification")} footer={renderFooter}>
        {isUploadLoading && <CircularLoader />}
        <div className={styles.verificationStepsContainer} />
        {renderVerificationSteps()}
        {isCameraOpened && isPermitted && (
          <PhotoCapture
            isOpened={isCameraOpened}
            setIsOpened={setIsCameraOpened}
            setCapturedImage={setCapturedImage}
          />
        )}
        {isOverlayVisible && (
          <InfoModal
            title={t("civilIfVerificationTitle")}
            subTitle={t("civilIfVerificationSubTitle")}
            fieldInfoImage={civilIdSelfie}
            handleCloseClick={handleCloseClick}
          />
        )}
      </MainLayout>
      {showAlert && (
        <AlertPhotoCapture
          text={t("enableAccessManually")}
          rightIcon={closeIcon}
          setShowAlert={setShowAlert}
          styling={{ backGroundColor: "#ffedf0", textColor: "#e94a66" }}
        />
      )}
    </>
  );
};

VerificationSteps.propTypes = {
  data: PropTypes.array.isRequired,
  setVerificationData: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  renderFooter: PropTypes.func.isRequired,
};

export default VerificationSteps;
